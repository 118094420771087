html{
	direction:rtl; 
}
body{
	text-align:right; 
}
.menu-primary > li.menu-item.menu-item-has-children > a::after {
	right:auto;
	left:0; 
}
.navbar-right .search-option{
	padding:0px 30px 0px 0px;
}
.menu-primary .sub-menu > li.menu-item:hover > a, .menu-primary .sub-menu > li.menu-item.active > a {
    padding: 0px 35px 0px 0px;
}
.menu-primary .sub-menu > li.menu-item.menu-item-has-children > a::after {
	right:auto;
	left:0;
	content: "\f053";
	margin-left:0px;
	margin-right:0px;
}
.menu-primary .sub-menu li.menu-item > .sub-menu{
	left:auto;
	right:100%;
	margin-left:0px; 
	margin-right:15px; 
}
.menu-primary .sub-menu > li.menu-item > a::before {
	left:auto;
	right: 0;
}
.heading-wrapper{
	text-align:right; 
}
.list-style-one li{
	padding-left:0px;
	padding-right:30px;
}
.list-style-one li::before, .list-style-one li::after {
	left:auto;
	right:0; 
}
.list-style-one li::after {
	left:auto;
	right:5px; 
}
.pricing-plans-one .pricing-feature-list li{
	padding: 0px 30px 0px 0px;
}
.pricing-plans-one .pricing-feature-list li::before {
	left:auto;
	right:0;  
}
.pricing-plans-two .pricing-feature-list li{
	padding: 0px 30px 0px 0px;
}
.pricing-plans-two .pricing-feature-list li::before {
	left:auto;
	right:0;  
}
.pricing-plans-three .pricing-feature-list li{
	padding: 0px 30px 0px 0px;
}
.pricing-plans-three .pricing-feature-list li::before {
	left:auto;
	right:0;  
}
.newsletter-form.style-two button{
	right:auto;
	left:20px; 
}
.text-right{
	text-align: left !important;
}
.text-left{
	text-align: right !important;
}
.footer-nav li{
	padding-left:0px;
	padding-right:30px;
}
.footer-nav li::before {
	left:auto;
	right: 0;
}
.contact-info li{
	padding-left:0px;
	padding-right: 35px;
}
.contact-info li::before {
	left:auto;
	right: 0;
}
.right-col-full .col-lg-6:first-child, .right-col-full .col-md-6:first-child{
	padding-left:15px;
	padding-right: calc(((100vw - (100vw - 100%)) - 1170px) / 2);
}
.right-col-full .col-lg-6:last-child, .right-col-full .col-md-6:last-child{
	padding-right:15px;
	padding-left: 0;
}
.left-col-full .col-lg-6:last-child, .left-col-full .col-md-6:last-child{
	padding-right: 15px;
	padding-left: calc(((100vw - (100vw - 100%)) - 1170px) / 2);
}
.left-col-full .col-lg-6:first-child, .left-col-full .col-md-6:first-child{
	padding-left:15px;
	padding-right: 0;
}
.btn + .btn{
	margin-left:0px;
	margin-right: 0px;
}
.btn > i{
	margin-right:0px;
	margin-left: 10px;
}
.features-block.theme-two .text{
	padding-left:0px;
	padding-right: 30px;
}
.features-block.theme-two h4::before, .features-block.theme-two h4::after {
	left:auto;
	right:-30px; 
}
.features-block.theme-two h4::after {
	left:auto;
	right:-24px; 
}
.subscribe-form-wrapper .btn{
	right:auto;
	left: 5px;
}
.subscribe-form-wrapper .form-control{
	padding: 0px 15px 0px 215px;
}
.slick-slider{
	direction:ltr !important; 
}
.client-testimonial.theme-one .testimonial-text::before {
	transform: rotate(90deg) skew(-52deg);
	left:auto;
	right:50px;  
}
.client-testimonial.theme-one .testimonial-text::after {
	border-right: 0px solid transparent;
	border-left: 40px solid transparent;
	left:auto;
	right:50px;
}
.client-testimonial.theme-one .client-info-wrapper{
	flex-direction: row-reverse;
}
.client-testimonial.theme-one .client-img{
	margin-right:0px;
	margin-left:20px;
}
.client-testimonial.theme-one .slick-prev, .client-testimonial.theme-one .slick-next{
	left: 95px;
	right: auto;
}
.client-testimonial.theme-one .slick-next {
    left: 140px;
    right: auto;
}
.client-testimonial.theme-one blockquote::before {
	left:auto;
	right: -25px;
}
.client-testimonial.theme-three {
    padding:60px 60px 60px 0px;
    margin-left:0px;
    margin-right:30px;
    border-left:none;
    border-right:3px solid rgba(255,255,255,0.2);
}
.client-testimonial.theme-three .slick-dots{
	margin-left:0px;
	margin-right:-72px;
	width: auto;
	left:auto;
	right:0;  
}
.client-testimonial.theme-three .testimonial-text{
	padding: 0px 90px 0px 0px;
}
.client-testimonial.theme-three .testimonial-text::before {
	left:auto;
	right:0; 
}
.text-center {
    text-align: center !important;
}
.text-center .heading-wrapper {
    text-align: center !important;
}
.news-grid.column-2 .article-details{
	padding-left:0px;
	padding-right:60px;
}
.news-grid.column-2 .article-details::before {
	left:auto;
	right: 0;
}
.post-meta span.entry-meta{
	padding-left:0px;
	padding-right: 28px;
	margin-right:0px;
	margin-left: 15px;
}
.post-meta span.entry-meta::before {
	left:auto;
	right: 0;
}
.sidebar.right-side{
	padding-left:0px;
	padding-right: 50px;
}
.sidebar.left-side{
	padding-right:0px;
	padding-left: 50px;
}
.widget.search-box button{
	left: 10px;
	right:auto;
}
.widget.search-box form input[type="search"]{
	padding-left:40px;
	padding-right:15px;
}
.sidebar ul li{
	padding: 0px 0px 10px 30px;
}
.sidebar ul li::after {
	left: 10px;
	right:auto;
	content: "\f104";
}
ul.st-recent-posts li .article-details{
	padding: 0 20px 0 0px;
}
blockquote{
	padding: 30px 60px 30px 30px;
	margin: 30px 25px 30px 0px;
}
blockquote::before {
	right: -25px;
	left:auto;
}
.entry-tags .label{
	padding: 0px 30px 0px 0px;
}
.entry-tags .label::before {
	right: 0;
	left:auto;
}
.tagcloud a, .entry-tags a{
	margin-right: 0px;
	margin-left: 6px;
}
@media only screen and (max-width:991px) {
	#navigation .menu-primary .child-link{
		right:auto;
		left:10px;
	}
	.menu-primary .sub-menu > li.menu-item.menu-item-has-children > a::after {
		display:none; 
	}
	.menu-primary .sub-menu li.menu-item > .sub-menu{
		margin-left: 0px;
		margin-right:0px;
	}
	#navigation .close-btn{
		right: 30px;
		left: auto;
	}
	#navigation{
		transform: translateX(-120%);
	}
}
blockquote {
	direction: rtl;
}
.dc-signin.theme-two .st-tab-btn {
	left: 0 !important;
	right: auto;
}
.dc-signin.theme-two .st-tab-btn .nav-tabs .nav-item {
	text-align: left;
}

.dc-signin.theme-two .st-tab-btn .nav-tabs .nav-item .nav-link, .dc-signin.theme-two .st-tab-btn .nav-tabs .nav-link {
    -webkit-border-radius: 0px 6px 6px 0px;
    border-radius: 0px 6px 6px 0px;
}