/* Template Name: DCode - Saas & Software Landing Page Templates
   Author: SacredThemes
   Author URL: https://sacredthemes.net
   Created: August 2020
   Version: 1.6
   File Description: Main Css file of the template
*/
/*----------------------------------------------*
	CSS Index
/*----------------------------------------------*
01.) Variables
02.) General              
03.) Header
04.) Page Layouts
05.) Landing Pages
06.) Footer
07.) Inner Pages
08.) Blog Layout
09.) 404 and Comingsoon Page CSS
10.) Sign-In and Sign-Up
11.) Timeline
12.) Responsive
/-------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,700;1,800&display=swap');
/*----------------------------------------------*
01.) Variable CSS
/*----------------------------------------------*/
:root {
 --body-text-color:#3f3f3f;
 --heading-text-color:#111111;
 --menu-link-color:#111111;
 --white-color:#ffffff;
 --very-light-gray:#f2f2f2;
 --light-gray:#ebebeb;
 --gray:#cccccc;
 --dark-gray:#797979;
 --success:#28a745;
 --success-bg:#d4edda;
 --info:#17a2b8;
 --info-bg:#cce5ff;
 --warning:#ffc107;
 --warning-bg:#fff3cd;
 --danger:#dc3545;
 --danger-bg:#f8d7da;
}
/*----------------------------------------------*
02.) General CSS
/*----------------------------------------------*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{
	margin:0;
	padding:0;
	border:0;
	outline:0
}
html,body,form,fieldset,p,div,h1,h2,h3,h4,h5,h6{
	-webkit-text-size-adjust:none
}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{
	display:block;
	clear:both;
}
html {
	font-family: 'Microsoft YaHei',"Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif; 
	-ms-text-size-adjust:100%;
	-webkit-text-size-adjust:100%;
	font-size: 62.5%;
}
body {
	font-size:16px;
	line-height:1.5; 
	overflow-x: hidden;
	min-width:320px; 
	/*-webkit-overflow-scrolling: touch;*/
	color:var(--body-text-color);
	background:#ffffff;
	font-family: 'Lato', sans-serif;
}
body.dc-six{
	overflow-x: hidden !important;
}
a {
	background:transparent; 
	text-decoration:none; 
	-webkit-tap-highlight-color:transparent; 
	color:#2a1fbc;
}
a:active {
	outline: 0;
}
a:active, a:hover, a:focus {
	color:var(--menu-link-color);
	text-decoration:none; 
}
b, strong{
	font-weight:bold; 
}
audio, canvas, progress, video {
	display:inline-block;
	vertical-align: baseline;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
svg:not(:root){
	overflow: hidden;
}
hr {-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;}
pre{
	overflow: auto;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap:break-word;
}
code, kbd, pre, samp{
	font-family: monospace; 
	font-size: 1rem
}
mark{
	background:#ff0; 
	color:#111111;}
table {
	border-collapse:collapse; 
	border-spacing: 0;
}
td, th {
	padding:0;
}
ul, ol{
	list-style: none outside none;
}
img{
	vertical-align:middle;
	border:0;
}
*, ::after, ::before {
	-webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
	margin:0;
	padding:0;
	line-height:1.2;  
}
h1, .h1{
	font-family: 'Source Sans Pro', sans-serif;
	font-size:48px;
	font-weight:bold;
	color:var(--heading-text-color);
	letter-spacing:-1px;
	margin-bottom:18px; 
}
h2,.h2{
	font-family: 'Source Sans Pro', sans-serif;
	font-size:42px;
	font-weight:bold;
	color:var(--heading-text-color);
	letter-spacing:-1px;
	margin-bottom:18px;
}
h3,.h3{
	font-family: 'Source Sans Pro', sans-serif;
	font-size:36px;
	font-weight:bold;
	color:var(--heading-text-color);
	letter-spacing:-0.6px;
	margin-bottom:18px;
}
h4,.h4{
	font-family: 'Source Sans Pro', sans-serif;
	font-size:32px;
	font-weight:bold;
	color:var(--heading-text-color);
	letter-spacing:-0.4px;
	margin-bottom:18px;
}
h5,.h5{
	font-family: 'Source Sans Pro', sans-serif;
	font-size:24px;
	font-weight:600;
	color:var(--heading-text-color);
	letter-spacing:0px;
}
h6,.h6{
	font-family: 'Lato', sans-serif;
	font-size:18px;
	font-weight:normal;
	color:var(--heading-text-color);
}

p:not(:empty){
	margin-bottom:18px; 
}
.heading-fonts{
	font-family: 'Source Sans Pro', sans-serif;
}
.special-fonts{
	font-family:'Alegreya', serif;
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
    z-index: 9999999;
}
#preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
#preloader #status .d-loader{
	width: 90px;
	height: 90px;
	position: relative;
	margin: 100px auto;
}
.container{
	max-width: 1200px;
}
.container-fluid{
	padding-left:60px;
	padding-right:60px; 
}
.overflow-hidden{
	overflow:hidden !important; 
}
[type="button"], [type="reset"], [type="submit"], button{
	outline:none !important; 
}
.btn{
	font-size:14px;
	letter-spacing:1px;
	text-transform:uppercase;
	font-weight:800;
	padding:0px 30px;
	line-height:48px;
	-webkit-border-radius:6px;
	border-radius:6px;
	cursor:pointer;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s  ease;
	outline:0; 
}
.btn + .btn{
	margin-left:15px;
}
.btn-large{
	font-size:16px;
	line-height:56px;
	min-width:270px; 
}
.btn-small{
	font-size:13px;
	line-height:36px;
}
.btn-full{
	width:100%;
	text-align:center; 
}
.btn-primary:hover, .btn-primary:focus{
	background-position:bottom center;
}
.btn-outline-primary{
	line-height:44px; 
}
.btn-outline-primary.btn-large{
	line-height:52px;  
}
.btn > i{
	margin-right:10px;
	font-size:24px;
	line-height:1;
	position: relative;
	top: 3px; 
}
.btn.link-btn{
    padding: 0;
    text-transform: none;
    letter-spacing: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    outline: none !important;
	box-shadow: none;
	border-bottom:2px solid var(--light-gray);
	border-radius:0px; 
}
.btn.link-btn:hover{
	border-bottom:2px solid var(--dark-gray); 
}
.style-dark{
	color:var(--white-color) !important;
}
.style-dark a{
	color:var(--white-color);	
}
.style-dark h1, .style-dark h2, .style-dark h3, .style-dark h4, .style-dark h5{
	color:var(--white-color) !important;	
}
.empty-space-10{
	height:10px; 
}
.empty-space-20{
	height:20px; 
}
.empty-space-30{
	height:30px; 
}
.empty-space-40{
	height:40px; 
}
.empty-space-50{
	height:50px; 
}
.empty-space-60{
	height:60px; 
}
.empty-space-80{
	height:80px; 
}
.empty-space-100{
	height:100px; 
}
.pt-10{
	padding-top:10px; 
}
.pt-15{
	padding-top:15px; 
}
.pt-30{
	padding-top:30px; 
}
.pt-40{
	padding-top:40px; 
}
.pt-50{
	padding-top:50px; 
}
.pt-60{
	padding-top:60px; 
}
.pt-80{
	padding-top:80px; 
}
.pt-90{
	padding-top:100px; 
}
.pt-100{
	padding-top:100px; 
}
.pb-10{
	padding-bottom:10px; 
}
.pb-15{
	padding-bottom:15px; 
}
.pb-30{
	padding-bottom:30px; 
}
.pb-40{
	padding-bottom:40px; 
}
.pb-50{
	padding-bottom:50px; 
}
.pb-60{
	padding-bottom:60px; 
}
.pb-80{
	padding-bottom:80px; 
}
.pb-90{
	padding-bottom:100px; 
}
.pb-100{
	padding-bottom:100px; 
}
.mt-10{
	margin-top:10px; 
}
.mt-15{
	margin-top:15px; 
}
.mt-30{
	margin-top:30px; 
}
.mt-40{
	margin-top:40px; 
}
.mt-50{
	margin-top:50px; 
}
.mt-60{
	margin-top:60px; 
}
.mt-80{
	margin-top:80px; 
}
.mt-90{
	margin-top:100px; 
}
.mt-100{
	margin-top:100px; 
}
.mb-10{
	margin-bottom:10px; 
}
.mb-15{
	margin-bottom:15px; 
}
.mb-30{
	margin-bottom:30px; 
}
.mb-40{
	margin-bottom:40px; 
}
.mb-50{
	margin-bottom:50px; 
}
.mb-60{
	margin-bottom:60px; 
}
.mb-80{
	margin-bottom:80px; 
}
.mb-90{
	margin-bottom:100px; 
}
.mb-100{
	margin-bottom:100px; 
}
.form-group {
    margin-bottom:10px;
}
.form-group label{
    font-weight:600;
    cursor:pointer;
    margin-bottom:4px;  
}

.form-control{
	height: 48px;
	padding: 0 15px;
	font-size: 15px;
	-webkit-border-radius:6px;
	border-radius: 6px;
	color:var(--body-text-color);
}
.form-control-lg{
	height: 58px;
	font-size:18px; 
}
textarea.form-control{
	height: 150px;
	padding: 15px 15px; 
}
.style-dark .form-control{
	background: transparent;
	border-color:rgba(255,255,255,0.2);
	color:var(--white-color);
}
.style-dark  ::-webkit-input-placeholder { /* Edge */
	color:var(--white-color);
	opacity:0.3;   
}
.style-dark  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color:var(--white-color);
	opacity:0.3;
}
.style-dark  ::placeholder {
	color:var(--white-color);
	opacity:0.3;
}
.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-next {
    right: -25px;
}
.slick-prev {
    left: -25px;
}
.slick-prev::before, .slick-next::before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev::before {
    content: '←';
}
.slick-next::before {
    content: '→';
}
#theme-option{
	display:none !important; 
}
.option-panel{
    position: fixed;
    top:175px;
    left: -200px;
    width:200px;
    height: auto;
    z-index: 13;
    background: #fff;
    padding: 20px;
    border-radius: 0px 8px 8px 0px;
    -webkit-border-radius: 0px 8px 8px 0px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    overflow: inherit;
   -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	text-align: center;
}
.option-panel .switcher-btn a.settings {
	background: #ffffff;
	height: 50px;
	position: absolute;
	right: -50px;
	top: 20px;
	width: 50px;
	padding: 0px;
	border-radius: 0 6px 6px 0;
	font-size: 28px;
	text-align: center;
	line-height: 48px;
	color: var(--heading-text-color);
	-webkit-box-shadow: 12px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 12px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.option-panel h3{
	text-align:center;
	font-size:18px;
	margin-bottom: 10px;
}
.option-panel ul.pattern-color-list{
	text-align:center;
	margin-bottom:20px;
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
}
.option-panel ul.pattern-color-list li{
	display: inline-block;
}
.option-panel ul.pattern-color-list li:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 0;
}
.option-panel ul.pattern-color-list li a {
    cursor: pointer;
    display: block;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    margin: 3px;
}
.option-panel ul.pattern-color-list li a:before{
	content:"";
	font-family: "Font Awesome 5 Free";
	color:#ffffff;
	font-size:18px;
	font-weight:900;
	line-height: 40px; 
}
.option-panel ul.pattern-color-list li a.active:before{
	content:"\f00c";
}
.option-panel ul.pattern-color-list li a.default-color {
	background: -webkit-linear-gradient(-135deg, #4688eb 0%, #291fbc 100%);
	background: -moz-linear-gradient(-135deg, #4688eb 0%, #291fbc 100%);
	background: linear-gradient(-135deg, #4688eb 0%, #291fbc 100%);
}
.option-panel ul.pattern-color-list li a.orange-color {
	background: -webkit-linear-gradient(-135deg, #fea845 0%, #f46a5f 100%);
	background: -moz-linear-gradient(-135deg, #fea845 0%, #f46a5f 100%);
	background: linear-gradient(-135deg, #fea845 0%, #f46a5f 100%);
}
.option-panel ul.pattern-color-list li a.green-color {
	background: -webkit-linear-gradient(-135deg, #4bbf90 0%, #3eb9db 100%);
	background: -moz-linear-gradient(-135deg, #4bbf90 0%, #3eb9db 100%);
	background: linear-gradient(-135deg, #4bbf90 0%, #3eb9db 100%);
}
.option-panel .btn + .btn{
	margin:0;
}
.option-panel .btn{
	display:none; 
}
.option-panel .btn.enable{
	display:inline-block; 
}
/*----------------------------------------------*
03.) Header CSS
/*----------------------------------------------*/
#master-head{
    position:relative;
    right: 0;
    left: 0;
    top: 0;
    width:100%; 
    background-color: transparent;
    border: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    padding:0px;
}
#master-head::before {
    background: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    right: 0;
    content: "";
    display: block;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.navigation-in #master-head::before {
    opacity: 1;
    visibility: visible;
}
#master-head.menu-absolute{
	position:absolute; 
}
#master-head.menu-fixed {
    background: #ffffff;
    -webkit-box-shadow: 0 4px 15px -10px rgba(0,0,0,.6);
    box-shadow: 0 4px 15px -10px rgba(0,0,0,.6);
    position: fixed;
    animation: slide-down 0.7s;
}
@keyframes slide-down {
	0% {
		opacity:0;
		transform:translateY(-100%);} 
	100%{
		opacity:1;
		transform: translateY(0);
	} 
}
.logo-container{
	padding:25px 0px 25px;
}
.menu-fixed .logo-container{
	padding:15px 0px 15px;	
}
.logo{
	display:block;
}
.logo img{
	max-height:50px;
}
.logo .logo-dark{
    display:inline-block;
}
.logo .logo-light{
    display:none;
}
.menu-light .logo-dark{
    display:none;
}
.menu-light .logo-light{
    display:inline-block;
}
.menu-fixed .logo-dark{
    display:inline-block;
}
.menu-fixed .logo-light{
    display:none;
}
.menu-toggle-btn{
	display:none;
}
#navigation .close-btn {
    height: 32px;
    width: 32px;
    position: absolute;
    left: 30px;
    top: 30px;
    cursor: pointer;
}
#navigation .close-btn::before, #navigation .close-btn::after {
    height: 3px;
    width: 32px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    content: "";
    display: block;
    background: var(--heading-text-color);
    border-radius: 4px;
    top: 15px;
    position: absolute;
}
#navigation .close-btn::after {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
#master-head .navbar-toggle {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: 3em;
	width: 3em;
	font-size: 12px;
	transition: all .2s;
	-webkit-tap-highlight-color: transparent;
	display: block;
}
.navbar-toggle .burger-lines {
    top: 50%;
    margin-top: -.125em;
}
.navbar-toggle .burger-lines, .navbar-toggle .burger-lines::after, .navbar-toggle .burger-lines::before {
    pointer-events: none;
    display: block;
    content: "";
    width: 100%;
    border-radius: .25em;
    background-color:var(--heading-text-color);
    height: .25em;
    position: absolute;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}
.menu-light .navbar-toggle .burger-lines, .menu-light .navbar-toggle .burger-lines::after, .menu-light .navbar-toggle .burger-lines::before{
	background-color:var(--white-color);
}
.navbar-toggle .burger-lines, .navbar-toggle .burger-lines::after, .navbar-toggle .burger-lines::before {
    transition: top .2s .2s,left .1s,transform .2s,background-color .4s .2s;
}
.navbar-toggle .burger-lines::before {
    left: 1em;
    top: 1em;
}
.navbar-toggle .burger-lines::after {
    left: 0;
    top: -1em;
}
.navbar-toggle .burger-lines::after, .navbar-toggle .burger-lines::before {
    width: 2em;
}
.active .navbar-toggle .burger-lines, .active .navbar-toggle .burger-lines::after, .active .navbar-toggle .burger-lines::before {
    transition: background-color .2s,top .2s,left .2s,transform .2s .15s;
}
.active .navbar-toggle .burger-lines {
    background-color: initial;
}
.active .navbar-toggle .burger-lines::after, .active .navbar-toggle .burger-lines::before {
    left: .5em;
    top: 0;
}
.active .navbar-toggle .burger-lines::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.active .navbar-toggle .burger-lines::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
#master-head.menu-fixed .navbar-toggle .burger-lines, #master-head.menu-fixed .navbar-toggle .burger-lines::after, #master-head.menu-fixed .navbar-toggle .burger-lines::before{
	background-color: var(--heading-text-color);
}
#master-head.menu-fixed .active .navbar-toggle .burger-lines{
	background-color: initial;
}
#navigation .menu-primary .child-link{
	display:none; 
}
.menu-primary{
	display:inline-block; 
}
.menu-primary li.menu-item{
	display:inline-block;
	padding: 0px 10px;
	font-weight:600;
	position:relative; 
}
.menu-primary > li.menu-item.menu-item-has-children > a:after{
	content:"\f078";
	font-size:13px;
	font-family: "Font Awesome 5 Free";
	position:relative;
	line-height:1; 
	right:0;
	top:50%;
	margin-top: -5px;
	margin-left:5px; 
}
.menu-primary > li.menu-item > a{
	display:block;
	font-size:17px; 
	padding:0px 15px;
	line-height:100px; 
	color:var(--menu-link-color);
	-webkit-transition:all 0.4s ease; 
	transition:all 0.4s ease;
}
.menu-light .menu-primary li.menu-item a{
	color:var(--white-color);
}
.menu-light .menu-primary > li.menu-item:hover > a, .menu-light .menu-primary > li.menu-item.active > a{
	opacity:0.6;
	color:var(--white-color);
}
.menu-fixed .menu-primary li.menu-item a{
	color:var(--menu-link-color);
}
.menu-primary  .sub-menu{
	position:absolute;
	top:100%;
	background: var(--white-color);
	-webkit-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition:all 0.4s ease-in-out; 
	transition:all 0.4s ease-in-out;
	padding: 30px 15px;
	min-width:280px;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	transform-origin: top;
	opacity: 0;
	visibility:hidden;
	z-index:-100;
}
.menu-primary .sub-menu li.menu-item > .sub-menu{
	left:100%;
	top: -30px;
	margin-left: 15px; 
}
.menu-primary li.menu-item:hover > .sub-menu{
	opacity:1;
	visibility:visible;
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	z-index: 1;
}
.menu-primary .sub-menu > li.menu-item{
	padding:8px 15px;
	display:block; 
}
.menu-primary .sub-menu > li.menu-item > a{
	color:var(--menu-link-color);
	padding: 0px 0px;
	font-size:15px;
	position:relative;
	-webkit-transition:all 0.4s ease-in-out; 
	transition:all 0.4s ease-in-out;
	display: block;
}
.menu-primary .sub-menu > li.menu-item > a:before{
	height:3px;
	width:0px;
	content:"";  
	display:inline-block;
	position:absolute;
	top:10px;
	left:0;
	background:var(--heading-text-color);
	-webkit-transition:all 0.4s ease-in-out; 
	transition:all 0.4s ease-in-out;
}
.menu-primary .sub-menu > li.menu-item:hover > a, .menu-primary .sub-menu > li.menu-item.active > a{
	padding: 0px 0px 0px 35px;
}
.menu-primary .sub-menu > li.menu-item:hover > a:before, .menu-primary .sub-menu > li.menu-item.active > a:before{
	width:25px;
}
.menu-primary > li.menu-item.mega-menu{
	position:static; 
}
.menu-primary .mega-menu-inner{
	width:100%;
	max-width:1170px; 
	left: 0;
	right:0;
	margin:auto;  
	display: flex;
	flex-wrap: nowrap;
}
.menu-primary .mega-menu-inner > li.menu-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
.menu-primary .mega-menu-inner > li.menu-item > .sub-menu{
	position:static;
	opacity:1;
	transform:none;
	visibility:visible;
	box-shadow:none;
	margin: 0;
	padding: 0;     
}
.menu-primary .sub-menu > li.menu-item.col-title > a{
	font-family: 'Source Sans Pro', sans-serif;
	font-size:20px;
	font-weight: bold;
	padding:0px 15px 0px 15px;
	margin-bottom:15px; 
}
.menu-primary .sub-menu > li.menu-item.col-title > a:before{
	display:none; 
}
.menu-primary .sub-menu > li.menu-item.col-title > a:after{
	height: 3px;
	width:45px;
	content: "";
	display:block;
	background:var(--heading-text-color);
	margin-top:10px; 
}
.menu-primary .sub-menu > li.menu-item.col-title:hover > a{
	color:inherit; 
}
.menu-primary .sub-menu > li.menu-item.menu-item-has-children > a::after {
    content: "\f054";
    font-size: 13px;
    font-family: "Font Awesome 5 Free";
    position: absolute;
    line-height: 1;
    right: 0;
    top: 50%;
    margin-top: -5px;
    margin-left: 5px;
}
.navbar-right{
	display:flex;
	flex-wrap:nowrap; 
}
.navbar-right .search-option{
	padding:0px 0px 0px 30px; 
}
.search-option .search-btn a{
	background:var(--heading-text-color);
	color:var(--white-color);
	line-height:42px; 
	width:42px;
	height:42px;
	border-radius:42px;
	display: block;
	text-align:center;
	position:relative;
	margin:3px 0px;
	font-size:20px; 
}
.search-option .search-btn a:before{
	content: '';
	position: absolute;
	border: rgba(0,0,0,0.3) solid 6px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-animation: ripple-out 1.3s 1s infinite;
    animation: ripple-out 1.3s 1s infinite;
    border-radius:42px;
    z-index:-1;
}
/* Ripple Out */
@-webkit-keyframes ripple-out {
	100% {
    	top: -12px;
    	right: -12px;
    	bottom: -12px;
    	left: -12px;
    	opacity: 0;
  	}
}
@keyframes ripple-out {
	100% {
    	top: -12px;
    	right: -12px;
    	bottom: -12px;
    	left: -12px;
    	opacity: 0;
  	}
}
.style-dark.search-option .search-btn a{
	color: var(--heading-text-color);
	background: var(--white-color);
}
.style-dark.search-option .search-btn a:before{
	border: rgba(255,255,255,0.3) solid 6px;
}
.menu-fixed .style-dark.search-option .search-btn a{
	background: var(--heading-text-color);
	color: var(--white-color);
}
.menu-fixed .style-dark.search-option .search-btn a:before{
	border: rgba(0,0,0,0.3) solid 6px;
}
/*----------------------------------------------*
04.) Page Layouts
/*----------------------------------------------*/
.page-header.dc-four{
	padding-bottom:150px;
}
.page-header.dc-six, .page-header.dc-seven{
	position:relative; 
}
.page-header.dc-seven:before{
	height:800px;
	width:800px;
	border-radius:800px;
	background: var(--heading-text-color);
	content: "";
	position: absolute;
	left: -32%;
	top:50%;
	transform:translateY(-50%);
}
.page-header.dc-six:before{
	height: 140%;
	width: 120%;
	border-radius: 80px;
	background: var(--heading-text-color);
	content: "";
	position: absolute;
	left: -16%;
	top: -68%;
	transform: rotate(-30deg);    
}
.page-header.dc-six:after{
	content: "";
	position: absolute;
	left:60%;
	top:-100px; 
	transform:translateX(-50%);
	background-position:center top;
	width:100%;
	height:100%;
	background-repeat:no-repeat;
}
.page-header.dc-six > *{
	position:relative;
	z-index:1;  
}
.page-header.dc-eight:before{
	height:100%;
	width:50%;
	background:url("../images/lms-bg.png") no-repeat scroll 0px 0px;
	content: "";
	position: absolute;
	right:0;
	top:0;
}
.full-width .container{
	width:100%;
	max-width:100%;  
}
.right-col-full .container, .left-col-full .container{
	width:100%;
	max-width:100%; 
}
.right-col-full .col-lg-6:first-child, .right-col-full .col-md-6:first-child{
	padding-left:calc(((100vw - (100vw - 100%)) - 1170px) / 2);
}
.right-col-full .col-lg-6:last-child, .right-col-full .col-md-6:last-child{
	padding-right:0;
}
.left-col-full .col-lg-6:last-child, .left-col-full .col-md-6:last-child{
	padding-right:calc(((100vw - (100vw - 100%)) - 1170px) / 2);
}
.left-col-full .col-lg-6:first-child, .left-col-full .col-md-6:first-child{
	padding-left:0;
}
.full-width-img img{
	width:100%; 
}
.section-padding{
	padding:49px 0px; 
}
.page-header{
    display: flex;
    height: 100%;
    align-items: center;
}
.full-height{
	min-height:100vh; 
}
.heading-wrapper{
	margin-bottom:30px; 
}
.heading-wrapper span{
	font-family:'Alegreya', serif;
	font-style:italic; 
}
.heading-wrapper span.sub-title{
	text-transform: uppercase;
	font-weight:700;
	font-size: 18px;
	letter-spacing:1px; 
	font-family:'Source Sans Pro', sans-serif;
	font-style:normal; 
}
.style-dark .heading-wrapper span{
	color:#ffffff; 
}
.lead-text, .lead-text p{
	font-size:20px;
	line-height:1.6;  
}
.intro-header-slider{
	padding-top:60px; 
	margin-bottom:-300px; 
}
.heading-wrapper.with-separator h1:after, 
.heading-wrapper.with-separator h2:after,
.heading-wrapper.with-separator h3:after{
	height:6px;
	width:60px;  
	content:"";
	display:block;
	margin-top:18px; 
}
.heading-wrapper.with-separator.text-center h1:after, 
.heading-wrapper.with-separator.text-center h2:after,
.heading-wrapper.with-separator.text-center h3:after{
	display:table; 
	margin-left:auto; 
	margin-right:auto; 
}
.text-wrapper{
	margin-bottom:30px; 
}
.text-wrapper ul{
	margin-bottom:30px; 
}
.text-wrapper a{
	font-weight:600;
	font-style:italic;  
}
ul.support-details li{
	margin:8px 0px;
	display:block;
	font-size:18px; 
}
ul.support-details li strong{
	color:var(--heading-text-color);
}
.rounded-6{
	border-radius:6px !important;
}
.rounded-8{
	border-radius:8px !important;
}
.screenshot-section{
	position:relative; 
}
.screenshot-slider{
    margin-bottom: -60px;
    margin-top:0px;
}
.screenshot-section:before{
	content:"";
	height:425px;
	width:100%;
	position:absolute;
	top:0;    
}
.ss-wrapper {
    max-width: 1170px;
    position: relative;
    margin: 0px auto 0px;
}
.ss-wrapper .laptop-img-bg {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.ss-wrapper .screenshot-slider {
    position: relative;
    padding: 5.15% 17.5% 7% 17.7%;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    display: block;
}
.screenshot-slider .slick-arrow{
	background:var(--heading-text-color);
	height:50px;
	width:50px;
	line-height:50px;
	color:var(--white-color);
	-webkit-border-radius:50%;
	border-radius:50%; 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.screenshot-slider .slick-prev::before, .screenshot-slider  .slick-next::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color:var(--white-color);
    font-size:24px;
    line-height: 50px;
    opacity:1; 
}
.screenshot-slider .slick-prev::before{
	content:"\f053";
	margin-right:5px; 
}
.screenshot-slider  .slick-next::before{
	content:"\f054";
	margin-left:5px;
}
.screenshot-slider .slick-prev{
	left:60px; 
}
.screenshot-slider .slick-next{
	right:60px; 
}
.call-to-action-content > h2{
	margin:0px; 
}
.call-to-action-content h2 span{
	font-style:italic; 
}
.call-to-action-content p{
	margin-top:15px; 
	margin-bottom:0; 
}
.list-style-one li{
	margin-bottom:12px;
	position:relative;
	padding-left:30px; 
}
.list-style-one li:before, .list-style-one li:after{
	content:"";
	width:3px;
	height:10px;
	background:var(--heading-text-color);
	position:absolute;
	top:9px;
	left:0;
}
.list-style-one li:after{
	left:5px;
	width:10px;  
}
.text-wrapper p:last-child{
	margin-bottom:0; 
}

.close-search{
	position:absolute;
	top:30px;
	right:60px;   
}
.close-search .lines{
	position:relative;
	height:36px;
	width:36px;
	display: block;
	cursor:pointer; 
}
.close-search .lines:after, .close-search .lines:before{
	position:absolute;
	top:0;
	background:rgba(255,255,255,0.8);
	height:36px;
	width:2px;
	content:"";
	top:0;
	-webkit-transform:rotate(45deg);  
	transform:rotate(45deg);  
}
.close-search .lines:before{
	-webkit-transform:rotate(-45deg);  
	transform:rotate(-45deg);
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width:18px;
    height:18px;
    padding:0px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background:var(--light-gray);
    border:3px solid var(--gray);
    border-radius:20px;  
}
.style-dark .slick-dots li button{
	background:var(--white);
}
.slick-dots li.slick-active button{
	background:var(--gray); 
}
.gradient-heading-bg{
	position:relative; 
}
#accordion .panel{
	border-bottom:1px solid var(--light-gray);
	padding:20px 0; 
}
#accordion .panel:last-child{
	border-bottom:0px;	
}
#accordion .panel-heading .panel-title{
	font-size:20px;
	margin-bottom:0;
	letter-spacing:0; 
}
#accordion .panel-title a{
	position:relative;
	display:block;
	padding:0 30px 0 0;
}
#accordion .panel-title a.collapsed{
	color:var(--heading-text-color);
}
#accordion .panel-default .panel-title a::after {
    font-family: 'Line Awesome Free';
    font-style: normal;
    font-size: 24px;
    content: "\f106";
    color: var(--body-text-color);
    font-weight: 900;
    position:absolute;
    top:-2px;
    right:0px;   
}
#accordion .panel-default .panel-title a.collapsed::after {
    content: "\f107";
}
#accordion .panel-body{
	padding:30px 30px 15px 0px; 
}
#accordion .panel-body p:last-child{
	margin-bottom:0; 
}
#accordion.style-2 .panel{
	border-radius: 6px;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	background: var(--white-color);
	border: none;
	padding:20px 20px;
}
#accordion.style-2 .panel:not(:last-child){
	margin-bottom:10px; 
}
#accordion.style-2 .panel-heading .panel-title{
	font-size:18px; 
}
#accordion.style-2 .panel-default .panel-title a::after {
	background:var(--heading-text-color);
	height:32px;
	width:32px;
	border-radius:50px;
	line-height:32px;
	text-align:center;
	font-size:18px;
	color:var(--white-color);
	top:-5px;
	font-family: "Font Awesome 5 Free";
	content:"\f077";  
}
#accordion.style-2 .panel-default .panel-title a.collapsed::after {
    content: "\f078";
}
ul.two-col{
	display:flex;
	flex-wrap:wrap;
}
ul.two-col li{
	flex:0 0 50%; 
	max-width:50%; 
	width:100%;  
}
ul.three-col li{
	flex:0 0 33.3334%; 
	max-width:33.3334%; 
	width:100%; 
}
/*----------------------------------------------*
05.) Landing Pages
/*----------------------------------------------*/
.pricing-plans-one .price-card {
	background:var(--white-color);
	-webkit-border-radius:8px;
	border-radius:8px; 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	margin-top:30px;
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out;  
}
.pricing-plans-one .price-card .card-header{
	background:transparent;
	padding:50px 30px 30px;  
	border:none; 
	-webkit-border-radius:8px;
	border-radius:8px; 
}
.pricing-plans-one .card-title{
	margin-bottom:30px; 
}
.pricing-plans-one .plan-cost{
	font-size:60px;
	font-weight:700;
	font-family:"Source Sans Pro", sans-serif;
	letter-spacing:-1px; 
	color: var(--heading-text-color);
	line-height:0.8;
	margin-bottom:5px;  
}
.pricing-plans-one .plan-cost .plan-cost-prefix{
	display:inline;
	font-size:36px;
	line-height: inherit;
	vertical-align: super;
	font-weight:900; 
}
.pricing-plans-one .plan-validity{
	margin:0;
	text-transform:uppercase;
	letter-spacing:0px;
	font-size:16px;  
}
.pricing-plans-one .card-body{
	padding:0px 30px 50px; 
}
.pricing-plans-one .pricing-feature-list{
	font-size:18px;
	font-weight:600;
	font-family:"Source Sans Pro", sans-serif;
	color: var(--heading-text-color);
	margin:45px 0px; 
}
.pricing-plans-one .pricing-feature-list li{
	margin:15px auto;
	padding:0px 0px 0px 30px;
	position:relative;
	display:table;
}
.pricing-plans-one .pricing-feature-list li:before{
	content:"\f00c";
	font-size:18px;
	font-family: "Font Awesome 5 Free";
	font-weight:900;
	position:absolute;
	left:0;
	top:0px;   
}
.pricing-plans-one .price-card.popular {
	position: relative;
	top:-25px; 
}
/* Pricing Style 2 */
.pricing-plans-two .price-card {
	background:var(--white-color);
	-webkit-border-radius:8px;
	border-radius:8px; 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	margin-top:120px;
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out;
	position:relative;  
}
.pricing-plans-two .price-card .card-header{
	background:transparent;
	padding:120px 30px 0px;  
	border:none; 
	-webkit-border-radius:8px;
	border-radius:8px; 
}
.pricing-plans-two .card-title{
	margin-bottom:18px; 
}
.pricing-plans-two .card-title:after{
	height: 6px;
	width: 60px;
	content: "";
	display: block;
	margin-top: 18px;
	background: var(--heading-text-color);
	margin-left: auto;
	margin-right: auto;
}
.pricing-plans-two .plan-cost-wrapper {
    background: var(--heading-text-color);
    color: var(--white-color);
    border: 10px solid var(--white-color);
    border-radius: 50%;
    display: block;
    margin: 0px auto 0px;
    width: 210px;
    height: 210px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -105px;
    padding: 35px 0;
}
.pricing-plans-two .price-card:before{
	width: 180px;
    height: 180px;
    position:absolute;
    z-index:-1; 
    content:"";
     -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	top:-90px;
	left:50%;
	-webkit-transform: translateX(-50%);
    transform: translateX(-50%);  
}
.pricing-plans-two .plan-cost{
	font-size:60px;
	font-weight:700;
	font-family:"Source Sans Pro", sans-serif;
	letter-spacing:-1px; 
	color: var(--white-color);
	line-height:0.8;
	margin-bottom:5px;  
}
.pricing-plans-two .plan-cost .plan-cost-prefix{
	display:inline;
	font-size:36px;
	line-height: inherit;
	vertical-align: super;
	font-weight:900; 
}
.pricing-plans-two .plan-validity{
	margin:0;
	text-transform:uppercase;
	letter-spacing:0px;
	font-size:16px;  
}
.pricing-plans-two .card-body{
	padding:0px 30px 50px; 
}
.pricing-plans-two .pricing-feature-list{
	font-size:18px;
	font-weight:600;
	font-family:"Source Sans Pro", sans-serif;
	color: var(--heading-text-color);
	margin:45px 0px; 
}
.pricing-plans-two .pricing-feature-list li{
	margin:15px auto;
	padding:0px 0px 0px 30px;
	position:relative;
	display:table;
}
.pricing-feature-list li.disabled{
	color:var(--gray); 
}
.pricing-plans-two .pricing-feature-list li:before{
	content:"\f00c";
	font-size:18px;
	font-family: "Font Awesome 5 Free";
	font-weight:900;
	position:absolute;
	left:0;
	top:0px;   
}
.pricing-feature-list li.disabled:before{
	content:"\f00d" !important;
	color:#ea3939 !important; 
}
/* Pricing Style 3 */
.pricing-plans-three .price-card {
	background:var(--white-color);
	-webkit-border-radius:8px;
	border-radius:8px; 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	margin-top:30px;
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out;  
}
.pricing-plans-three .price-card .card-header{
	background:transparent;
	padding:50px 30px 30px;  
	border:none; 
	-webkit-border-radius:8px;
	border-radius:8px; 
}
.pricing-plans-three .card-title{
	margin-bottom:30px; 
}
.pricing-plans-three .plan-cost{
	font-size:60px;
	font-weight:700;
	font-family:"Source Sans Pro", sans-serif;
	letter-spacing:-1px; 
	color: var(--heading-text-color);
	line-height:0.8;
	margin-bottom:5px;
	display: inline-block; 
}
.pricing-plans-three .plan-cost .plan-cost-prefix{
	display:inline;
	font-size:36px;
	line-height: inherit;
	vertical-align: super;
	font-weight:900; 
}
.pricing-plans-three .plan-validity{
	margin:0;
	letter-spacing:0px;
	font-size:20px;
	display: inline-block;
	font-weight:700; 
}
.pricing-plans-three .card-body{
	padding:0px 30px 50px; 
}
.pricing-plans-three .pricing-feature-list{
	font-size:18px;
	font-weight:600;
	font-family:"Source Sans Pro", sans-serif;
	color: var(--heading-text-color);
	margin:45px 0px; 
}
.pricing-plans-three .pricing-feature-list li{
	margin:15px 0;
	padding:0px 0px 0px 30px;
	position:relative;
	display:table;
}
.pricing-plans-three .pricing-feature-list li:before{
	content:"\f00c";
	font-size:18px;
	font-family: "Font Awesome 5 Free";
	font-weight:900;
	position:absolute;
	left:0;
	top:0px;   
}
.features-block{
	position: relative;
	z-index: 1;
	margin-bottom: 30px;
}
.features-block p:last-child{
	margin-bottom:0; 
}
.features-block.theme-one .inner-box{
	background:var(--white-color);
	-webkit-border-radius:6px;
	border-radius:6px; 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out;
	padding:40px 30px;
	position:relative; 
	text-align:center;
	overflow:hidden;
	cursor:pointer; 
}
.features-block.theme-one .inner-box:before{
	-webkit-transform:translateY(102%); 
	transform:translateY(102%); 
	width:100%;
	position:absolute;
	top:0;
	left:0;
	content:"";
	height:100%;
	-webkit-transition:all 500ms ease-in-out;  
	transition:all 500ms ease-in-out;
}
.features-block.theme-one .inner-box:hover:before{
	-webkit-transform:translateY(0); 
	transform:translateY(0);
}
.features-block.theme-one .inner-box > *{
	position:relative;
}
.features-block.theme-one .icon{
	margin:0px auto 18px; 
	text-align:center;
	display:table; 
}
.features-block.theme-one .icon img{
	max-width:90px;
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out;
}
.features-block.theme-one .icon img.hover{
	display:none;
}
.features-block.theme-one .inner-box:hover img.normal{
	display:none;
}
.features-block.theme-one .inner-box:hover img.hover{
	display:block; 
}
.features-block.theme-one h4{
	font-size:24px;
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out; 
}
.features-section .inner-row .col-lg-6:nth-child(2){
	margin-top:80px; 
}
.features-section .inner-row .col-lg-6:nth-child(3){
	margin-top:-80px; 
}
.features-block.theme-one .inner-box:hover{
	color:var(--white-color);
}
.features-block.theme-one .inner-box:hover h4{
	color:var(--white-color);
}
/*  Feature Style 2 */
.features-block.theme-two .inner-box{
	background:rgba(255,255,255,0.1);
	-webkit-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
	padding: 40px 30px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	cursor:pointer; 
}
.features-block.theme-two .inner-box:hover{
	background:rgba(255,255,255,0.12);
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); 
}
.features-block.theme-two .text{
	padding-left:30px; 
}
.features-block.theme-two h4 {
    font-size: 24px;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    position:relative;
}
.features-block.theme-two h4::before, .features-block.theme-two h4::after {
    content: "";
    width: 3px;
    height: 10px;
    background: var(--heading-text-color);
    position: absolute;
    top: 11px;
    left:-30px;
}
.features-block.theme-two h4::after {
    left:-24px;
    width: 10px;
}
.style-dark .features-block.theme-two h4::before, .style-dark .features-block.theme-two h4::after {
	 background: var(--white-color);
}
.features-block.theme-three .inner-box {
    background: var(--white-color);
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border:1px solid var(--light-gray); 
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    padding:30px 15px;
    position: relative;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}
.features-block.theme-three .inner-box:hover{
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    border:1px solid var(--gray); 
}
.features-block.theme-three .icon {
    margin: 0px auto 18px;
    text-align: center;
    display: table;
}
.features-block.theme-three .icon img {
    max-width:68px;
    max-height:68px; 
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}
.features-block.theme-three h4 {
    font-size:18px;
    margin-bottom:0; 
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}
.features-block.theme-four .inner-box{
	background:var(--white-color);
	-webkit-border-radius:6px;
	border-radius:6px; 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out;
	padding:40px 50px;
	position:relative; 
	overflow:hidden;
	cursor:pointer;
	display: flex;
}
.features-block.theme-four .inner-box:before{
	display:none; 
}
.features-block.theme-four .inner-box > * {
    position: relative;
}
.features-block.theme-four .icon {
    margin:0px 30px 0px 0px;
    text-align: center;
    display: table;
}
.features-block.theme-four .icon img {
    max-width: 90px;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}
.features-block.theme-four h4 {
    font-size: 24px;
    margin:0 0 10px;  
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}
.features-block.theme-five:last-child{
	margin:0; 
}
.features-block.theme-five .inner-box{
	-webkit-transition:all 400ms ease-in-out;  
	transition:all 400ms ease-in-out;
	padding:15px 0px;
	position:relative; 
	overflow:hidden;
	cursor:pointer;
	display: flex;
}
.features-block.theme-five .inner-box:before{
	display:none; 
}
.features-block.theme-five .inner-box > * {
    position: relative;
}
.features-block.theme-five .icon {
    margin:0px 30px 0px 0px;
    text-align: center;
    display: table;
}
.features-block.theme-five .icon img {
    max-width:68px;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}
.features-block.theme-five h4 {
    font-size: 24px;
    margin:0 0 10px;  
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}
.dc-features-group{
	position: relative;
	overflow: hidden;
}
.dc-features-group > .dc-features-item {
    margin-bottom: -1px;
    border-right: 1px solid #f0f0f0;
}
.dc-features-group > .dc-features-item:nth-child(n), .dc-features-group > .dc-features-item:nth-child(2n), .dc-features-group > .dc-features-item:nth-child(3n) {
    border-bottom: 1px solid #f0f0f0;
}
.dc-features-group > .dc-features-item > a {
    position: relative;
    overflow: hidden;
    display: block;
}
.dc-features-group > .dc-features-item .dc-features-item-front{
    position: relative;
}
.dc-features-group > .dc-features-item .dc-features-item-front, .dc-features-group > .dc-features-item .dc-features-item-hover {
    min-height: 333px;
    height: auto;
    padding:20px 45px;
    -webkit-transition: all 0.4s cubic-bezier(.25,.46,.45,.94);
    transition: all 0.4s cubic-bezier(.25,.46,.45,.94);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.dc-features-group > .dc-features-item .dc-features-item-hover {
    position: absolute;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    opacity: 0;
    visibility: hidden;
}
.dc-features-group > .dc-features-item .dc-features-item-hover::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
    background-color:var(--heading-text-color);
}
.dc-features-group > .dc-features-item:nth-child(3n) {
    border-right: none;
}
.st-tab-btn .nav-tabs{
	border-bottom:3px solid var(--light-gray);
}
.dc-features-item-front .inner-box, .dc-features-item-hover .inner-box{
    width: 100%;
    text-align: center;
}
.dc-features-item-hover .inner-box{
	position: relative;
	z-index: 2;
}
.dc-features-group .icon{
    margin-bottom: 30px;
}
.dc-features-group .icon img{
    max-width: 90px;
	-webkit-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
}
.dc-features-group .dc-features-title {
    font-size:24px;
    margin-bottom: 0;
}
.dc-features-item-hover .dc-features-title{
	color: #ffffff;
	margin-bottom: 30px;	
}
.dc-features-item-hover p{
    color: #ffffff;
    margin-bottom: 0;
}
.dc-features-group a:hover .dc-features-item-front{
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}
.dc-features-group a:hover .dc-features-item-hover{
    top: 0%;
    opacity: 1;
    visibility: visible;
}
.tab-wrapper{
	margin:0px; 
}
.tab-wrapper.vertical-tab{
    display: flex;
    flex-wrap: wrap;
}
.st-tab-btn{
	padding:0px 0px 0px;
	margin-bottom:15px; 
}
.vertical-tab .st-tab-btn{
	flex:0 0 25%; 
	width:100%;
	max-width:25%; 
	padding:0px;
	margin:0; 
}
.vertical-tab .st-tab-btn .nav-tabs{
	border-bottom:0px;
	display: block;
}
.vertical-tab .st-tab-btn .nav-tabs .nav-item{
	display:block;
	padding:0;
	margin:0;  
}
.st-tab-content{
	padding:30px 0px 0px; 
}
.vertical-tab .st-tab-content{
	flex:0 0 75%; 
	width:100%;
	max-width:75%; 
	padding:0px 0px 0px 50px;
	border-left:3px solid var(--light-gray);
}
.st-tab-btn .nav-tabs .nav-item{
	border:0px;
	margin:0px 15px 0px 0px;
	padding:0px 0px;
}
.st-tab-btn .nav-tabs .nav-item:last-child{
	margin:0; 
}
.st-tab-btn .nav-tabs .nav-item .nav-link, .st-tab-btn .nav-tabs .nav-link{
	border:0px;
	background:none;
	color:var(--heading-text-color);
	font-weight:700;
	font-family: 'Source Sans Pro', sans-serif;
	font-size:18px;
	padding:15px;
	position:relative;
}
.vertical-tab .st-tab-btn .nav-tabs .nav-item .nav-link, .st-tab-btn .nav-tabs .nav-link{
	padding:15px 0px;
}
.st-tab-btn .nav-tabs .nav-item .nav-link:after{
	content:"";
	height:3px;
	background:var(--heading-text-color);
	position:absolute;
	bottom:-3px;
	right:0;
	width:0; 
	transition:all 0.5s ease-in-out; 
}
.vertical-tab .st-tab-btn .nav-tabs .nav-item .nav-link::after{
	bottom:0px;
	height:0;
	width:3px;
	right:-3px;   
}
.st-tab-btn .nav-tabs .nav-item.show .nav-link:after, .st-tab-btn .nav-tabs .nav-link.active:after{
	left:0;
	width:100%;
}
.vertical-tab .st-tab-btn .nav-tabs .nav-item.show .nav-link:after, .vertical-tab .st-tab-btn .nav-tabs .nav-link.active:after{
	top:0;
	height:100%;
	left:auto;
	width:3px;  
}
.testimonial-section{
	position:relative;
	overflow:hidden;  
}
.client-testimonial.theme-one .slick-list{
	overflow:visible !important; 
}
.client-testimonial.theme-one {
	max-width:800px;
	padding:0px 30px;
	width:100%;
	margin:40px auto 0;
	position:relative; 
}
.client-testimonial.theme-one .testimonial-slider{
	position:inherit;
}
.client-testimonial.theme-one .testimonial-text{
	background:#ffffff;
	-webkit-border-radius:8px;
	border-radius:8px;  
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	padding:50px 50px;
	position: relative;
}
.client-testimonial.theme-one .testimonial-text:after{
	width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid #fff;
    content:"";
    position:absolute;
    top:100%;
    left:50px; 
}
.client-testimonial.theme-one .testimonial-text::before {
	width: 60px;
	height: 60px;
	content: "";
	position: absolute;
	top: 100%;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
	transform: rotate(90deg) skew(52deg);
	z-index: -1;
	margin-top: -50px;
	left:50px; 
}
.client-testimonial.theme-one .slick-slide{
	padding: 0px 80px;
}
.client-testimonial.theme-one .client-info-wrapper{
	display: flex;
	flex-wrap: nowrap;
	width: auto;
	align-items: center;
	margin-top:60px; 
}
.client-testimonial.theme-one .client-img{
	background:#ffffff;
	-webkit-border-radius:80px;
	border-radius:80px;  
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	padding:6px;
	margin-right:20px; 
}
.client-testimonial.theme-one .client-img img{
	-webkit-border-radius:80px;
	border-radius:80px;
	max-width:80px; 
}
.client-testimonial.theme-one .client-info p, .client-testimonial.theme-two .client-info p, .client-testimonial.theme-three .client-info p, .client-testimonial.theme-four .client-info p{
	margin:0; 
}
.client-testimonial.theme-one .client-info h5, .client-testimonial.theme-two .client-info h5, .client-testimonial.theme-three .client-info h5, .client-testimonial.theme-four .client-info h5 {
	font-weight:700;
	font-size:20px;
}
.client-testimonial.theme-one blockquote{
	font-size:18px;
	font-style: italic;
	line-height: 1.5;
	border: none;
	margin: 0;
	padding: 0;
	position: static;
}
.client-testimonial.theme-one blockquote:before{
	background:#000000;
	border-radius:60px;
	padding:5px;
	height:50px;  
	width:50px;
	line-height:40px;
	text-align:center;
	color:#ffffff;
	content:"\f10d";
	font-family: "Font Awesome 5 Free";
	font-style:normal; 
	font-weight: 900;
	position:absolute;
	top:38px;
	left:-25px;
	font-size: 24px;  
}
.client-testimonial.theme-one .slick-prev, .client-testimonial.theme-one .slick-next{
	top: 100%;
	right:95px;
	left:auto; 
	background:transparent; 
	margin-top:-30px; 
	z-index: 1;
	height: 36px;
	width: 36px;
}
.slick-slider{
	direction:ltr !important; 
}
.slick-prev::before, .slick-next::before {
	font-family: 'Line Awesome Free';
	font-weight: 900;
	color:var(--heading-text-color);
	font-size:36px; 
}
.client-testimonial.theme-one .slick-prev{
	right:140px; 
}
.slick-prev::before{
	content:"\f30a";
}
.slick-next::before{
	content:"\f30b";
}
.call-to-action{
	width:100%; 
}
.call-to-action .call-btn{
	font-family:"Source Sans Pro",sans-serif;
	font-size:32px;
	line-height:1;
	border-bottom:1px solid;
	display:inline-block;
	font-weight:700;  
}
.call-to-action label, .subscribe-form-wrapper label{
	font-family:"Source Sans Pro",sans-serif;
	font-size:20px;
	font-weight:600; 
	line-height:1;
	margin-bottom:0px;
	color:var(--heading-text-color);
	display: block;
	margin-bottom:15px;
}
.subscribe-form-wrapper .or{
	display: block;
	margin:15px 0px; 
}
.subscribe-form-wrapper form{
	position:relative; 
}
.subscribe-form-wrapper .form-control{
	padding:0px 215px 0px 15px;
	height:58px;
	-webkit-border-radius:9px;  
	border-radius:9px;  
}
.subscribe-form-wrapper .btn{
	position:absolute;
	top:5px;
	right:5px;  
}
.testimonial-section.style-two:before{
    content:"";
    height:400px;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    right:0;     
}
.client-testimonial.theme-two .slick-slider{
	padding:30px 0px 30px; 
}
.client-testimonial.theme-two .slick-list{
	margin:0px -15px; 
}
.client-testimonial.theme-two .slick-slide{
	padding:0px 15px 30px; 
}
.client-testimonial.theme-two .testimonial-text {
    background:var(--white-color);
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 45px 30px 60px;
    position: relative;
    text-align:center; 
}
.client-testimonial.theme-two .testimonial-text::after{
    background:var(--heading-text-color);
    border-radius: 60px;
    padding: 5px;
    height: 50px;
    width: 50px;
    line-height: 40px;
    text-align: center;
    color:var(--white-color);
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    position: absolute;
    top: 100%;
    left:50%;
    margin-top:-25px;
    margin-left:-25px;
    font-size: 24px;
}
.client-testimonial.theme-two blockquote {
    font-size: 18px;
    font-style: italic;
    line-height: 1.5;
    border: none;
	margin: 0;
	padding: 0;
	position: static;
}
.client-testimonial.theme-two blockquote:before{
	content:"";
	display:none; 
}
.client-testimonial.theme-two .client-info{
	margin-top:30px; 
}
.client-testimonial.theme-two .slick-prev, .client-testimonial.theme-two .slick-next {
    top:100%;
    right:50%;
    left: auto;
    background: transparent;
    z-index: 1;
    height: 36px;
	width: 36px;
}
.client-testimonial.theme-two .slick-prev{
	right:50%; 
	margin-right:10px;
}
.client-testimonial.theme-two .slick-next {
	right:auto;
	left:50%;
	margin-left:10px;
}
.client-testimonial.theme-two .slick-disabled{
	opacity:0.4; 
}
.testimonial-section.style-three{
	background:url('../images/team-bg.jpg') no-repeat scroll center center;
	background-size:cover;  
}
.client-testimonial.theme-three blockquote {
    font-size: 22px;
    font-style: italic;
    line-height: 1.5;
    border: none;
	margin: 0;
	padding: 0;
	position: static;
}
.client-testimonial.theme-three blockquote:before{
	content:"";
	display:none; 
}
.client-testimonial.theme-three{
	padding:60px 0px 60px 60px;
	margin-left: 30px;
	border-left: 3px solid rgba(255,255,255,0.2);
}
.client-testimonial.theme-three .client-info {
    margin-top: 30px;
}
.client-testimonial.theme-three .testimonial-text{
	padding: 0px 0px 0px 90px;
	position: relative;
}
.client-testimonial.theme-three .testimonial-text::before {
    color: var(--white-color);
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    position: absolute;
    top:-8px;
    left:0;
    font-size:54px;
}
.client-testimonial.theme-three .slick-dots{
	left:0;
	bottom:auto;
	top:50%;
	transform:translateY(-50%);
	margin-left: -71px;   
}
.client-testimonial.theme-three .slick-dots li{
	display: block;
	margin:15px 0px;
	padding:0; 
}
.client-testimonial.theme-four .slick-list{
	margin:0 -15px;
}
.client-testimonial.theme-four .item {
    background: var(--white-color);
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 45px 30px;
    position: relative;
    margin:15px 15px; 
}
.client-testimonial.theme-four .client-info-wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    align-items: center;
    margin-top:0px;
    margin-bottom:20px;
    position:relative; 
}
.client-testimonial.theme-four .client-info-wrapper:before{
	content: "\f10d";
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	position: absolute;
	top:30px;
	right:0px;
	line-height: 40px;
	padding:0;
	color:var(--heading-text-color);
	opacity:0.1;
	font-size:48px;
}
.client-testimonial.theme-four .client-img {
    -webkit-border-radius:90px;
    border-radius:90px;
    margin-right: 20px;
}
.client-testimonial.theme-four .client-img img {
    -webkit-border-radius:90px;
    border-radius:90px;
    max-width:100px;
}
.client-testimonial.theme-four blockquote{
	margin: 0;
	border: 0;
	padding: 0;
}
.client-testimonial.theme-four blockquote::before{
	display:none; 
}
.client-testimonial.theme-four .slick-arrow {
    background: var(--heading-text-color);
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: var(--white-color);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    left: -60px;
}
.client-testimonial.theme-four .slick-arrow.slick-next{
	left:auto;
	right:-60px; 
}
.client-testimonial.theme-four .slick-prev::before, .client-testimonial.theme-four .slick-next::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--white-color);
    font-size: 24px;
    line-height: 50px;
    opacity: 1;
}
.client-testimonial.theme-four .slick-prev::before {
    content: "\f053";
    margin-right: 5px;
}
.client-testimonial.theme-four .slick-next::before {
    content: "\f054";
    margin-left: 5px;
}
.dc-two.about-section{
	background:transparent; 
}
.video-player{
	overflow:hidden;
	position:relative;
	padding-top:56.25%;
	width: 100%;     	
}
.video-wrapper iframe{
	width:100%;
	height:100%; 
	border:0; 
	position:absolute;
	top:0;
	left:0;
}
.dc-two.about-section .video-wrapper{
	text-align:center;
	position:relative;
	padding:115px 0px; 
}
.dc-two.about-section .video-wrapper:before{
	background:var(--heading-text-color);
	height:425px;
	width:425px;
	transform:translate(-50%, -50%);
	content:"";
	position:absolute;
	top:50%;
	left:50%;
	z-index:-1;
	border-radius:50%;       
}
.dc-two.about-section .video-player:before, .dc-two.about-section .video-player:after{
	content:"";
	position:absolute;
	z-index:-1;
	border-radius:50%;
	height: 486px;
	width: 486px;
	background-size:cover !important; 
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}
.dc-two.about-section .video-player:after{
	height:544px;
	width: 544px;
}
.dc-two.about-section .video-player::before{
	-webkit-animation: rotate-right 10s linear 0s infinite; 
	animation: rotate-right 10s linear 0s infinite; 
}
.dc-two.about-section .video-player::after {
	-webkit-animation: rotate-left 12s linear 0s infinite; 
	animation: rotate-left 12s linear 0s infinite; 
}
.circle-background-animation{
	padding:60px 0px 60px; 
}
.circle-background-animation img{
	position:relative; 
}
.circle-background-animation:before{
	content:"";
	height:617px;
	width:617px;
	left:0;
	top:0;
	right: 0;
	margin: auto;
	bottom:0; 
	position:absolute;
	-webkit-animation: rotate-right 10s linear 0s infinite; 
	animation: rotate-right 10s linear 0s infinite; 
}
@-webkit-keyframes rotate-right {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-left {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.icon-box.theme-one{
	text-align:center;
	padding:30px 30px 30px 30px;  
}
.icon-box.theme-one .icon{
	height:120px;
	width:120px;
	padding:10px;
	-webkit-border-radius:50%;
	border-radius:50%;
	background:var(--heading-text-color);
	line-height:100px;
	text-align:center;
	margin: 0px auto 30px; 
}
.icon-box.theme-one .icon img{
	max-width:64px; 
}
.icon-box.theme-one h4 {
    font-size: 24px;
}
.icon-box.theme-one p:last-child{
	margin-bottom:0;  
}
.icon-box.theme-two{
	text-align:center;
	padding:30px 30px 30px 30px;
	border:1px solid  var(--light-gray);
	-webkit-border-radius:8px;
	border-radius:8px;
	background:var(--white-color);
}
.icon-box.theme-two .icon{
	height:120px;
	width:120px;
	padding:10px;
	-webkit-border-radius:50%;
	border-radius:50%;
	background:var(--heading-text-color);
	line-height:100px;
	text-align:center;
	margin: 0px auto 30px; 
}
.icon-box.theme-two .icon img{
	max-width:64px; 
}
.icon-box.theme-two h4 {
    font-size: 24px;
}
.icon-box.theme-two p {
    margin-bottom:0; 
}
.page-header .screenshot-slider .slick-arrow{
	display:none !important; 
}
.page-header.dc-two .heading-wrapper{
	margin-top:50px;
}
.counter-wrapper{
	margin:0px -30px; 
}
.counter-block{
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%;
	padding:0px 30px; 
	position:relative; 
}
.counter-wrapper.two-col-stack{
	display:flex;
	flex-wrap:wrap;
}
.with-separator .counter-block:not(:last-child):after{
	border-right:2px dashed  #e1e1e1;
	content:"";
	height:100%;
	width:0;
	position:absolute;
	right:0;
	top:0;  
}
.counter-block .counter-title{
	font-size:17px;
	font-weight:bold;
	color:var(--heading-text-color);
	margin-top:10px;  
}
.counter-block .counter-number, .counter-block .counter-number-after{
	font-size:48px;
	font-weight:bold;
	color:var(--heading-text-color);
	font-family: 'Source Sans Pro', sans-serif;
	text-transform:uppercase;
	line-height: 1;
}
.style-dark .counter-block .counter-number, .style-dark .counter-block .counter-number-after{
	color:var(--white-color);
}
.style-dark .counter-block .counter-title{
	color:var(--white-color);
}
.style-dark .counter-block .counter-number-suffix{
	color:var(--white-color);
	background-clip: unset;
	background:transparent; 
}
.counter-block .counter-number-suffix{
	background:var(--heading-text-color) ;
	font-size:52px;
	font-weight:bold;
	font-family: 'Source Sans Pro', sans-serif;
	position:relative;
	top:-12px;
	line-height:0.8;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;   
}
.counter-block.with-icon{
	text-align:center;
	margin:30px 0px 0;  
}
.counter-block.with-icon .counter-icon{
	width:160px;
	height:160px;
	padding:30px;
	border:1px solid var(--light-gray);
	line-height:98px;  
	-webkit-border-radius:50%;
	border-radius:50%;
	margin:0px auto 30px;  
}
.style-dark .counter-block.with-icon .counter-icon{
	border:1px solid rgba(255,255,255,0.2);
}
.counter-block.with-icon .counter-icon img{
	max-width:68px;
}
.counter-wrapper.two-col-stack .counter-block{
	flex: 0 0 50%;
	max-width: 50%;
	width: 100%;
	margin:0; 
	padding:50px 30px;
}
.counter-wrapper.two-col-stack .counter-block.with-icon .counter-icon{
	padding: 0;
	border: 0px;
	height:auto;
	width:auto;
	margin: 0px auto 30px;
	line-height:normal; 
}
.counter-wrapper.two-col-stack.with-separator .counter-block:before {
	background:rgba(0,0,0,0.2);
	border:0;  
	left:30px;
	right:30px;
	content:"";
	height:1px;
	width:auto;
	display:block;
	position:absolute;
	bottom:0;  
}
.counter-wrapper.two-col-stack.with-separator .counter-block:last-child:before{
	height:0;
}
.counter-wrapper.two-col-stack.with-separator .counter-block::after {
	display:none;   
}
.counter-wrapper.two-col-stack.with-separator .counter-block:nth-child(2n + 1):after {
	background:rgba(0,0,0,0.2);
	border:0;  
	top:30px;
	bottom:30px;
	content:"";
	width:1px;
	height:auto;
	display:block; 
}
.style-dark .counter-wrapper.two-col-stack.with-separator .counter-block:nth-child(2n + 1):after, .style-dark .counter-wrapper.two-col-stack.with-separator .counter-block:before {
	background:rgba(255,255,255,0.2);
}
.companies-logo{
	margin-top:30px; 
}
.companies-logo.grid-view{
	margin:0px -15px;
	display:flex;
	flex-wrap:wrap;   
}
.companies-logo.grid-view .item{
	flex:0 0 20%;
	max-width:20%;
	width:100%;
	margin:30px 0px 0px;   
}
.companies-logo .slick-list{
	margin:0px -15px; 
}
.companies-logo .item{
	padding:0px 15px; 
}
.companies-logo .logo-wrapper{
	border:1px solid var(--light-gray);
	text-align:center;
	padding:10px 
}
.companies-logo .item img{
	display: inline-block;
}
.how-it-work-wrapper{
	display:flex;
	flex-wrap:wrap;  
	width:100%;
}
.how-it-work-wrapper .item{
	text-align:center;
	padding:15px 30px 0px;  
}
.how-it-work-wrapper.three-step .item{
	flex:0 0 33.3334%;
	max-width:33.3334%;
	width:100%;
}
.how-it-work-wrapper .step-number{
	height:120px;
	width:120px;
	line-height:120px;
	text-align:center;
	-webkit-border-radius:50%;
	border-radius:50%;
	margin:0px auto 15px;
	background:rgba(255,255,255,0.1);
	font-size:48px;
	font-weight:bold;
	font-family: 'Source Sans Pro', sans-serif;
	font-style:italic; 
}
.how-it-work-wrapper .step-title{
	font-size:24px; 
}
/*----------------------------------------------*
06.) Footer CSS
/*----------------------------------------------*/
.main-footer{
	padding:100px 0px;
	background-size:cover;
	background-position:top center;
	font-size: 16px;
	line-height: 1.5;  
}
.main-footer a{
	color:var(--body-text-color); 
}
.main-footer.style-dark a, .main-footer .style-dark a{
	color: var(--white-color);
}	
.social-media-links{
	display:flex;
	align-items:center; 
	flex-wrap: wrap;
}
.social-media-links > h3{
	font-size:18px;
	margin:0 0 10px 0;
}
.social-media-links ul{
	margin:0px;
	padding:0px;
	width:100%; 
}
.social-media-links li{
	padding:0;
	margin:0 2px;
	display:inline-block;   
}
.social-media-links li a{
	line-height:46px;
	width:48px;
	height:48px;
	display:block;
	font-size:24px;
	-webkit-border-radius:6px;
	border-radius:6px;
	text-align:center; 
	border:1px solid var(--light-gray);
	position:relative;
	overflow:hidden;  
}
.social-media-links li a:before{
	background:rgba(0,0,0,0.03);
	width: 100%;
	height: 100%;
	left:0; 
	content:"";
	position:absolute; 
	-webkit-transition:all 0.5s ease; 
	transition:all 0.5s ease;
	-webkit-transform:translateY(100%); 
	transform:translateY(100%); 
}
.style-dark .social-media-links li a{
	border:1px solid rgba(255,255,255,0.2);
}
.style-dark .social-media-links li a:before{
	background:rgba(255,255,255,0.1);
}
.copyright-bar{
	padding:20px 0px;
	font-size:16px;
	border-top:1px solid rgba(0,0,0,0.1);
}
.social-media-links li:hover a:before{
	-webkit-transform:translateY(0); 
	transform:translateY(0); 
}
.footer-theme-one{
	padding-top: 160px;
	position:relative; 
}
.footer-theme-one .container{
	position:relative; 
}
.footer-theme-one .footer-logo{
	position:absolute;
	height:500px;
	width:500px;
	text-align:center;
	padding:35px;
	z-index:-1;
	top:-100px;
	left:0; 
}
.footer-theme-one .footer-logo:before{
	height:500px;
	width:500px;
	content:"";
	display:block;
	-webkit-border-radius:50%;
	border-radius:50%;
	position:absolute; 
	top:0;
	left:0;   
}
.footer-logo img{
	max-width:180px;
	position:relative; 
}
.widget + .widget{
	margin-top:30px; 
}
.widget .widget-title{
	margin-bottom:25px; 
}
.widget .widget-title h3.title{
	font-size:20px;
	letter-spacing: 0;
}
.contact-info{
	margin-bottom:30px; 
}
.contact-info li{
	margin-bottom:15px;
	position:relative;
	padding-left:35px; 
}
.contact-info li:last-child{
	margin-bottom:0; 
}
.contact-info li:before{
	font-size:20px;
	font-family: "Font Awesome 5 Free";
	font-weight:900;
	position:absolute;
	left:0;
	top:-2px;   
}
.contact-info li.address-field:before{
	content:"\f1fa";
}
.contact-info li.email-field:before{
	content:"\f658";
}
.contact-info li.phone-field:before{
	content:"\f095";
}
.style-dark .contact-info li:before{
	color:var(--white-color); 
}
.contact-info.theme-two li{
	padding:0; 
}
.contact-info.theme-two li:before{
	content:"";
}
.contact-info.theme-two li label{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 20px;
	font-weight: bold;
	display: block;
}
.contact-info.theme-two li.email-field{
	font-size:20px; 
}
.contact-info.theme-two li.phone-field{
	font-size:24px; 
}
.footer-nav ul{
	margin:0;
	padding:0;  
}
.footer-nav li{
	margin-bottom:15px;
	position:relative;
	padding-left:30px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.footer-nav li a{
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.footer-nav li a:hover{
	opacity:0.6; 
}
.footer-nav li:last-child{
	margin-bottom:0; 
}
.footer-nav li:before{
	font-size:16px;
	font-family: "Font Awesome 5 Free";
	content:"\f28d";
	font-weight:400;
	position:absolute;
	left:0;
	top:1px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.style-dark .footer-nav li:before{
	color:var(--white-color);
}
.footer-nav li:hover:before{
	opacity:0.6;
}
.footer-theme-one .copyright-bar, .footer-theme-two .copyright-bar, .footer-theme-three .copyright-bar, .footer-theme-eight .copyright-bar{
	border-top:1px solid rgba(255,255,255,0.2); 
}
.about-info img{
	max-width:185px; 
}
.playstore-widget h3{
	font-size: 20px;
	letter-spacing: 0;
}
.playstore-widget ul{
	list-style:none;
	margin:0;
	padding:0;   
}
.playstore-widget ul li{
	list-style:none;
	margin:0 5px 0px 0;
	padding:0;   
	display: inline-block;
}
.playstore-widget ul li a{
	border: 1px solid rgba(0,0,0,0.1);
	position: relative;
	overflow: hidden;
	border-radius:6px;
	line-height:46px;
	padding:0px 15px 0px 45px;
	font-size:17px;
	font-family:"Source Sans Pro", sans-serif;
	font-weight:500;
	display: block;
	color:var(--heading-text-color);  
}
.style-dark .playstore-widget ul li a, .style-dark.playstore-widget ul li a{
	border: 1px solid rgba(255,255,255,0.2);
}
.playstore-widget ul li a:before{
	font-size:16px;
	font-family: "Font Awesome 5 Brands";
	content:"\f28d";
	font-weight:400;
	position:absolute;
	left:15px;
	top:50%;
	transform: translateY(-50%);
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	font-size:20px; 
}
.playstore-widget ul li.google a:before{
	content:"\f3ab";
}
.playstore-widget ul li.apple a:before{
	content:"\f179";
	font-size:24px;
}
.playstore-widget ul li a span{
	display:block;
	font-size:13px; 
	font-weight:normal;
	line-height:1.3; 
}
.playstore-widget.large ul li a{
	font-size:22px;
	font-weight:bold;
	line-height:1.2;
	padding: 10px 20px 10px 75px;
}
.playstore-widget.large ul li a::before {
	font-size:42px;
}
.playstore-widget.large ul li.apple a::before {
	font-size:48px;
}
.playstore-widget.large ul li a span{
	font-size:15px; 
}
.playstore-widget.stack-list{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
}
.playstore-widget.stack-list ul{
	list-style: none;
	margin: 0;
	padding: 0 30px 0 0;
	flex: 0 0 50%;
}
.playstore-widget.stack-list .qr-code-block{
	flex: 0 0 50%;
	width:100%; 
}
.playstore-widget.stack-list ul li{
	display: block;
	margin: 10px 0px;
}
.playstore-widget.stack-list ul a{
	width:100%;
	display: block;
}
.main-footer .playstore-widget ul li a::after{
    background: rgba(255,255,255,0.1);
    width: 100%;
    height: 100%;
    left: 0;
    content: "";
    position: absolute;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
.main-footer .playstore-widget ul li:hover a::after{
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.main-footer .image-wrapper{
	margin-bottom:30px;
}
.footer-theme-three{
	text-align:center; 
}
.footer-theme-eight{
	text-align:center;
	position:relative; 
}
.footer-theme-eight:before{
	content:"";
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}
.horizontal-menu{
	display:block;
	margin-bottom:30px; 
}
.horizontal-menu li{
	display:inline-block;
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px 15px;
	font-size: 15px;
}
.horizontal-menu li a{
	display:block; 
} 
.horizontal-menu li:before{
	content:"";
}
.newsletter-form.style-two{
	max-width:480px;
	margin:0px auto;  
}
.newsletter-form.style-two.align-left{
	max-width:380px;
	margin:0 0 30px;  
}
.newsletter-form.style-two h3{
	font-size:20px;
}
.presentation-section .newsletter-form.style-two h3{
	font-size:28px;
}
.presentation-section .newsletter-form.style-two{
	max-width:580px;
}
.footer-theme-three .social-media-links{
	margin-top:30px;
}
.newsletter-form.style-two form {
    position: relative;
}
.newsletter-form.style-two button{
	font-size:0; 
	position:absolute;
	padding:0;
	border:none;
	background:transparent;
	right:20px;    
	top: 50%;
	transform: translateY(-50%); 
}
.newsletter-form.style-two button:before{
	content: "\f1d8";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size:22px;
	color:var(--heading-text-color); 
}
.style-dark .newsletter-form.style-two button:before{
	color:var(--white-color); 
}
/*----------------------------------------------*
07.) Inner Pages CSS
/*----------------------------------------------*/
.st-breadcrumb {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 17px;
	margin: 0px auto 0px;
	display: table;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	background: rgba(0,0,0,0.08);
	padding: 10px 30px;
}
.st-breadcrumb li{
	display:inline-block;
	padding:0px 0px;  
}
.st-breadcrumb li a{
	padding:0px 5px;
}
.st-breadcrumb li span{
	padding:0px 5px;
	opacity:0.6; 
}
.st-breadcrumb li:not(.active):after{
	content:"/";
	margin:0px 10px; 
}
.st-team-member.theme-one{
	margin-top:30px;
}
.st-team-member.theme-one .st-team-image{
	position:relative;
	overflow:hidden;
	background:var(--heading-text-color);  
	cursor:pointer; 
}
.st-team-member.theme-one .st-team-image img{
	-webkit-transition:all 500ms ease-in-out;    
	transition:all 500ms ease-in-out;
}
.st-team-member.theme-one:hover .st-team-image img{
	opacity:0.1; 
}
.st-team-member.theme-one .team-social{
	position:absolute;
	bottom:0;
	padding:10px 15px;
	width:100%; 
	-webkit-transition:all 500ms ease-in-out;    
	transition:all 500ms ease-in-out;
	text-align:center;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}
.st-team-member.theme-one:hover .team-social{
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.st-team-member.theme-one .team-social a{
	text-align:center;
	display:inline-block;
	margin:0px 3px;
	border: 1px solid rgba(255,255,255,0.2);
	position: relative;
	overflow: hidden;
	line-height:36px;
	width: 38px;
	height: 38px;
	font-size: 20px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	color: var(--white-color);
}
.st-team-member.theme-one .team-social a::before {
    background: rgba(255,255,255,0.1);
    width: 100%;
    height: 100%;
    left: 0;
    content: "";
    position: absolute;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
.st-team-member.theme-one .team-social a:hover::before {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.st-team-member.theme-one .st-team-member-detail{
	text-align: center;
	padding: 15px 15px 0px;
}
.st-team-member.theme-one .member-name{
    font-size: 28px;
    margin: 0 0 10px 0;
    line-height: 1;
}
.st-team-member.theme-one .member-position{
	text-transform:uppercase;
	font-weight:600;
	letter-spacing:1px;
	line-height: 1;
	font-size: 15px;    
}
.map-custom-embed{
	display:block;
	width:100%;  
}
.map-custom-embed iframe{
	margin:0;
	padding:0;
	height: 550px;
	vertical-align: middle;
	width:100%; 
}
.contact-info-box{
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border: 1px solid var(--light-gray);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -webkit-border-radius:6px;
    border-radius:6px;
    margin-bottom:30px; 
}
.contact-info-box .icon{
	font-size:48px;
	color:var(--heading-text-color); 
	line-height: 1;
	margin-bottom:10px 
}
.contact-info-box h4{
	margin-bottom:10px; 
}
.contact-info-box p:last-child{
	margin-bottom:0; 
}
.contact-box-wrapper{
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	-webkit-border-radius: 6px;
	border-radius: 6px;	
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.contact-box-wrapper .form-box{
	padding:50px 50px;
	flex:0 0 calc(100% - 400px);
	max-width:calc(100% - 400px);
	width:100%; 
}
.contact-box-wrapper .contact-details-box{
	padding:50px 50px;
	background:var(--heading-text-color); 
	-webkit-border-radius:0px 6px 6px 0px;
	border-radius: 0px 6px 6px 0px;
	flex:0 0 400px;
	max-width:400px;
	width:100%; 
}
/*----------------------------------------------*
08.) Blog Layout CSS
/*----------------------------------------------*/
.news-grid{
	display: flex;
	flex-wrap:wrap;
	width: 100%;
}
.article-block{
	margin:0px 0px 50px;
}
.news-grid.column-3 .article-block{
	flex:0 0 33.3334%;
	max-width:33.3334%;
	width:100%; 
	padding:0px 15px;
	margin:0px 0px 50px;
}
.news-grid.column-2 .article-block{
	flex:0 0 50%;
	max-width:50%;
	width:100%; 
	padding:0px 15px;
	margin:0px 0px 50px;
}
.article-block .article-img{
	margin-bottom:30px;
	position:relative;
	overflow:hidden;
}
.news-grid .article-img{
	margin-bottom:30px;
	position:relative;
	overflow:hidden;  
}
.article-block .article-img img{
	-webkit-transition:all 0.5s ease; 
	transition:all 0.5s ease;
}
.article-block .article-img:hover img{
	-webkit-transition:all 0.8s ease; 
	transition:all 0.8s ease;
	-webkit-transform:scale(1.1); 
	transform:scale(1.1); 
}
.post-meta{
	margin-bottom:15px;
}
.post-meta > div, .post-meta > span{
	display:inline-block; 
}
.post-meta span{
	text-transform:uppercase;
	font-weight:600;
	font-size:14px; 
	font-family:'Source Sans Pro', sans-serif; 
}
.post-meta span.entry-meta{
	position:relative;
	padding-left:28px;
	margin-right:15px; 
}
.post-meta span.entry-meta:last-child{
	margin-right:0; 
}
.post-meta span.entry-meta:before{
	content:"D";
	color:var(--heading-text-color);
	display:inline-block;
	font-size:20px;
	position:absolute;
	left:0;
	line-height:1;
	font-family:"Material Design Icons";
	font-weight: normal; 
}
.post-meta span.entry-meta.entry-author:before{
	content:"\F0017";
	font-size:22px;
	top:-1px; 
}
.post-meta span.entry-meta.entry-date:before{
	content:"\F0E17";	
}
.post-meta span.entry-meta.entry-category:before{
	content:"\F0255";	
}
.news-grid .post-meta span.entry-meta{
	padding-left:0;
	margin-right:0; 
}
.news-grid .post-meta span.entry-meta:before{
	content:"";
	display: none;
}
.post-meta a{
	color:var(--dark-gray); 
	-webkit-transition:all 0.5s ease; 
	transition:all 0.5s ease; 
}
.news-grid .post-meta > div:not(:last-child):after, .news-grid .post-meta > span:not(:last-child):after{
	content:"/";
	margin:0px 6px;
	display:inline-block;
}
.article-block .entry-title{
	font-size:28px;
}
.news-grid .entry-title{
	font-size:24px;
	margin-bottom:0; 
}
.news-grid.column-2 .entry-title{
	font-size:28px;
}

.article-block .entry-title a{
	color:var(--heading-text-color);
	-webkit-transition:all 0.5s ease; 
	transition:all 0.5s ease; 
}
.pagination-wrapper{
	width:100%;  
	margin:30px 0px 0px 0px; 
}
.pagination-wrapper .pagination{
	display:block; 
}
.pagination li{
	display:inline-block;
	margin:0px 2px; 
}
.pagination li a, .pagination li span{
	height:48px;
	width:48px;
	line-height:48px;
	text-align:center;
	display:block;
	background:var(--light-gray);
	-webkit-border-radius:50px;
	border-radius:50px;
	font-size:20px;
	font-weight:600;
	color:var(--heading-text-color);
}
.pagination li .mdi{
	font-size:32px;
	position:relative;
	top:4px;
	line-height:1;   
}
.pagination li span.current{
	background:var(--heading-text-color);
	color:var(--white-color); 
}
.news-grid.column-2 .article-details{
	padding-left:60px;
	position:relative; 
}
.news-grid.column-2 .article-details:before{
	height: 3px;
	width: 30px;
	content: "";
	display: inline-block;
	position: absolute;
	top: 12px;
	left: 0;
	background: var(--heading-text-color);
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;   
}
.readmore-link{
	margin-top:15px; 
}
.filters{
	margin:0px 0px 50px;
	width: 100%;
	padding: 0px 15px;
	position:relative; 
}
.filters ul {
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: 3px solid var(--light-gray);
    text-align: center;
}
.filters ul li {
    display: inline-block;
    padding: 0px 10px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--heading-text-color);
    line-height: 1;
}
.filters li span {
    padding: 0px 5px 15px;
    display: block;
    cursor: pointer;
    position: relative;
}
.filters li span::after {
    height: 3px;
    width: 0;
    right: 0;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    position: absolute;
    top: 100%;
    background: var(--heading-text-color);
    content: "";
}
.filters li:hover span::after, .filters li.is-checked span::after {
    width: 100%;
    left: 0;
    right: auto;
}
.filters .select-filter{
	display:none;
	cursor: pointer;
	padding:0px 20px 15px;
	text-align: left;
	color:var(--heading-text-color);
	border-bottom: 3px solid var(--light-gray);
	margin-bottom:0px;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 700;
	font-size: 20px;
}
.sidebar.right-side{
	padding-left:50px; 
}
.sidebar.left-side{
	padding-right:50px; 
}
.sidebar .widget-title:after{
	height: 4px;
	width: 60px;
	content: "";
	display: block;
	margin-top: 18px;
	background:var(--heading-text-color); 
}
.sidebar ul li {
   	margin-bottom: 10px;
	position: relative;
	padding: 0px 30px 10px 0px;
	border-bottom: 1px solid var(--light-gray);
}
.sidebar ul li:last-child{
	margin-bottom:0px;
	border-bottom:0;
}
.sidebar ul li::after {
    position: absolute;
    top:1px;
    right:10px;
    content: "\f105";
    font-family:"Font Awesome 5 Free";
    font-weight:700; 
}
.sidebar a{
	color: var(--body-text-color);
}
.sidebar a:hover{
	color:var(--heading-text-color);
}
.widget.search-box form{
	position:relative; 
}
.widget.search-box form input[type="search"]{
	padding-right:40px; 
}
.widget.search-box button{
	background:none;
	color:var(--heading-text-color);
	border:none;
	position:absolute;
	top:8px;
	right:10px;
	font-size:22px;     
}
.sidebar .widget + .widget{
	margin-top:50px; 
}
.tagcloud a, .entry-tags a {
    border: 1px solid var(--light-gray);
    padding: 0 15px;
    color: var(--body-text-color);
    display: inline-block;
    font-size: 14px !important;
    line-height: 32px;
    margin-right: 6px;
    margin-bottom: 6px;
    -webkit-border-radius:6px;
    border-radius:6px;
    font-weight: 600;
    -webkit-transition:all 500ms ease-in-out; 
    transition:all 500ms ease-in-out; 
}
.tagcloud a:hover, .entry-tags a:hover{
	background:var(--heading-text-color);
	color:var(--white-color) !important; 
}
ul.st-recent-posts li{
	padding-right:0; 
}
ul.st-recent-posts li:after{
	display:none; 
}
ul.st-recent-posts li a{
	display:flex;
	flex-wrap:wrap;
	width:100%;   
}
ul.st-recent-posts li a:hover{
	color:var(--body-text-color); 
}
ul.st-recent-posts li .article-img{
    width: 100%;
    flex: 0 0 100px;
    max-width: 100px;
}
ul.st-recent-posts li .article-details{
	flex: 0 0 calc(100% - 120px);
	max-width: calc(100% - 120px);
	width: 100%;
	padding: 0 0 0 20px;
}
ul.st-recent-posts .entry-title{
	font-size: 16px;
	margin-bottom: 5px;
	font-weight: 600; 
}
ul.st-recent-posts span {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
}
.sidebar .social-media-links li{
	padding: 0;
	border-bottom:none; 
}
.sidebar .social-media-links li:after{
	display:none;
}
.single-post .st-breadcrumb{
	display:none;
}
.post-thumbnail{
	margin-bottom:30px; 
}
.single-post article h2, .h2{
	font-size:36px; 
}
.single-post article h3, .h3{
	font-size:30px; 
}
.single-post article h4, .h4{
	font-size:26px; 
}
.single-post article ul, .single-post article ol{
	margin-bottom:18px; 
}
blockquote {
    font-size:18px;
    font-style: italic;
    line-height: 28px;
    position: relative;
    padding: 30px 30px 30px 60px;
    clear: both;
    margin:30px 0 30px 25px;
    border:1px solid var(--light-gray); 
}
blockquote p:last-child{
	margin-bottom:0px; 
}
blockquote cite{
	display:block;
	font-weight:700;
	font-style:normal;
	margin-top:15px;    
}
blockquote::before {
    background: #000000;
    border-radius: 60px;
    padding: 5px;
    height: 50px;
    width: 50px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    position: absolute;
    top: 38px;
    left: -25px;
    font-size: 24px;
}
.entry-content-bottom{
	margin-top:50px; 
}
.entry-tags .label{
	display: block;
	font-size:18px;
	font-weight:bold; 
	font-family: 'Source Sans Pro', sans-serif;
	margin-bottom:10px;
	padding:0px 0px 0px 30px; 
	position:relative; 
}
.entry-tags .label:before{
	content: "\F04FB";
	color: var(--heading-text-color);
	display: inline-block;
	font-size: 24px;
	position: absolute;
	left: 0;
	line-height: 1;
	font-family: "Material Design Icons";
	font-weight: normal;
	top: 4px;
}
.inner-page-header .post-meta span.entry-meta{
	font-size: 16px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	background: rgba(0,0,0,0.08);
	padding: 10px 30px;
}
.style-dark .post-meta a {
	color:rgba(255,255,255,0.7); 
}
.style-dark .post-meta a:hover{
	color:rgba(255,255,255,1);
}
.inner-page-header .post-meta span.entry-meta::before{
	display:none; 
}
/*----------------------------------------------*
09.) 404 and Comingsoon Page CSS
/*----------------------------------------------*/
.countdown-timer ul{
	list-style:none; 
	display:flex;
	flex-wrap:wrap;
	margin:0px -15px;
	padding:0px 0px 30px; 
}
.countdown-timer ul li{
	list-style:none; 
	flex:0 0 25%;
	max-width:25%;
	width:100%;
	padding:0px 15px;
	text-align:center;
}
.countdown-timer ul li .count-item{
	border:1px solid rgba(0,0,0,0.1);
	-webkit-border-radius:6px;  
	border-radius:6px;
	padding:15px 15px;
}
.style-dark .countdown-timer ul li .count-item{
	border:1px solid rgba(255,255,255,0.2);
}
.countdown-timer ul li span{
	display:block;
	font-size:48px;
	line-height:1; 
	font-weight:bold; 
	font-family: 'Source Sans Pro', sans-serif;
}
.dc-comingsoon #main-logo img{
	max-height:60px; 
}
.dc-comingsoon .newsletter-form.style-two{
	margin:0;
	max-width:100%;  
}
.dc-comingsoon .newsletter-form.style-two h3 {
    font-size: 24px;
}
.dc-comingsoon .text-wrapper{
	margin-bottom:15px; 
}
.dc-comingsoon .social-media-links{
	margin-top:60px; 
}
/*----------------------------------------------*
10.) Sign-In and Sign-Up CSS
/*----------------------------------------------*/
.dc-signin{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	min-height: 100vh;
	width: 100%;
}
.box-header{
    position: relative;
    padding: 30px 30px 60px;
    margin-bottom: 30px;
}
.signin-wrapper .box-header::before {
    background:var(--heading-text-color);
	content: "";
	position: absolute;
	height: 300px;
	width: 480px;
	border-radius: 50%;
	top: -110px;
	left: -70px;
	z-index: 0;
	transform: rotate(-16deg);
}
.signin-wrapper .box-header > *{
	position:relative; 
}
.signin-wrapper{
	max-width:400px;
	width:100%;
	margin:30px auto;
	padding:0px 30px 30px;
	background: var(--white-color);
	-webkit-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
	overflow:hidden; 
}
.signin-wrapper .box-header{
    position: relative;
    padding: 40px 0px 30px;
    margin-bottom: 30px;
}
.or{
	margin:15px 0px;
	text-align:center;
	font-weight:600;
	text-transform:uppercase;     
}
.forgot-link, .signup-link, .signin-link{
	margin:15px 0px; 
}
.box-header img{
	max-height:55px;
	margin-bottom:15px; 
}
.box-header h1{
	line-height:1;
	font-size:28px;
}
.box-header h1 span{
	font-family: 'Alegreya', serif;
	font-style: italic; 
}
.btn.email-btn{
	background-image: -moz-linear-gradient(top, #e60023 0%, #c00000 100%);
	background-image: -webkit-linear-gradient(top, #e60023 0%, #c00000 100%);
	background-image: linear-gradient(to bottom, #e60023 0%, #c00000 100%); 
}
.btn.facebook-btn{
	background-image: -moz-linear-gradient(top, #16a8fc 0%, #036ee4 100%);
	background-image: -webkit-linear-gradient(top, #16a8fc 0%, #036ee4 100%);
	background-image: linear-gradient(to bottom, #16a8fc 0%, #036ee4 100%); 
}
.dc-signin.theme-two .signin-wrapper{
	max-width:1024px;
	display:flex;
	padding:0;   
}
.dc-signin.theme-two .intro-box{
	background:var(--heading-text-color);
	flex:0 0 60%;
	max-width:60%;
	padding:150px 50px 50px;
	position: relative;
}
.dc-signin.theme-two .intro-box img{
	max-height:55px; 
	margin-bottom:15px; 
}
.dc-signin.theme-two .st-tab-btn{
	position:absolute;
	top:0;
	right:0;   
}
.dc-signin.theme-two .st-tab-btn .nav-tabs{
	border:none;
	display: block;
	padding-top:30px; 
}
.dc-signin.theme-two .st-tab-btn .nav-tabs .nav-item .nav-link::after {
	content:"";
	display:none; 
}
.dc-signin.theme-two .form-box{
	flex: 0 0 40%;
	max-width: 40%;
	width: 100%;
	padding: 50px 50px;
}
.dc-signin.theme-two .st-tab-btn .nav-tabs .nav-item{
	display:block;
	margin:0;
	text-align:right; 
}
.dc-signin.theme-two .st-tab-btn .nav-tabs .nav-item .nav-link, .dc-signin.theme-two .st-tab-btn .nav-tabs .nav-link{
	color:var(--white-color);
	padding:10px 15px;
	display: inline-block;
	-webkit-border-radius: 6px 0 0px 6px;
	border-radius: 6px 0 0px 6px;
}
.dc-signin.theme-two .st-tab-btn .nav-tabs .nav-link.active{
	background:var(--white-color);
	color:var(--heading-text-color);  
}
.dc-signin .btn.link-btn{
	font-size:16px; 
}
.enquiry-form-box .form-wrapper{
	background: var(--white-color);
	-webkit-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
	border: 1px solid var(--light-gray);
	padding: 30px;
	max-width: 400px;
	margin: auto;
	width:100%; 
	display:table; 
}
.enquiry-form-box .form-wrapper .form-header{
	text-align:center;
}
.enquiry-form-box .form-wrapper .form-header h4{
	font-size:28px; 
}
.enquiry-form-box .form-wrapper .form-header h4 span{
	font-family: 'Alegreya', serif;
	font-style:italic; 
}
.form-wrapper .seo-rocket img{
	position:absolute;
	left:-20px;
	bottom: -100px;
	z-index: -1;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	-webkit-transition:all 2s ease 1s; 
	transition:all 2s ease 1s; 
}
.animation-in .form-wrapper .seo-rocket img{
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
/*----------------------------------------------*
11.) Timeline CSS
/*----------------------------------------------*/
.timeline::before {
    content: '';
    position: absolute;
    top: 50%;
    left:0;
    right:0px;
    height:12px; 
    background:var(--heading-text-color);
    margin-top:-6px;
}
.timeline .timeline-item{
	text-align:center;
	padding:0px 15px;
	position:relative;   
}
.timeline .timeline-item:before{
	background:var(--heading-text-color);
	height:32px;
	width:32px;
	content:"";
	position:absolute;
	top:50%;
	left:50%; 
	transform:translate(-50%, -50%);
	border-radius:50px;
	border:4px solid var(--white-color);      
}
.timeline .step-number{
	color:var(--heading-text-color);
	font-family: 'Alegreya', serif;
	font-style:italic;
	font-size:98px;
	font-weight:bold;
	line-height:0.8;
	height:98px; 
}
.timeline .step-number span{
	display:block; 
}
.timeline .step-number{
	position:absolute;
	text-align:center;
	width:100%;
	top:30px;
}
.timeline .timeline-wrapper{
	padding-top:100px;
	position:relative; 
}
.timeline .timeline-item .content{
	padding:80px 0 0 0; 
}
.timeline .timeline-item .content p{
	margin-bottom:0; 
}
.timeline .timeline-item h4{
	font-size:24px;
	margin-bottom:10px; 
}
.timeline .timeline-item:nth-child(even) .timeline-wrapper{
	padding-top:0px;
	padding-bottom:100px; 
}
.timeline .timeline-item:nth-child(even) .content{
	padding:0 0 80px 0;
}
.timeline .timeline-item:nth-child(even) .step-number{
	top:auto;
	bottom:30px;
}
#upload {
    display: none;
}
.upload-main {
    min-height: 50px;
    margin: 10px 0 10px;
    display: flex;
    align-items: center;
}
.upload-main::before {
    content: '';
    background: url(../images/upload-icon.svg) no-repeat 0 0;
    width: 28px;
    height: 26px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
}
.upload-path {
    display: none;
}
.upload-path.not-empty {
    display: inline-block;
}
.upload-path {
    background:var(--white-color);
    padding: 0 16px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    color:var(--heading-text-color);
    margin-right: 15px;
    line-height: 1;
}
.upload-path span {
    vertical-align: middle;
    display: inline-block;
    line-height: 42px;
}
.upload-path .upload-close {
    background: url(../images/upload-close.svg) no-repeat 0 0;
    width: 22px;
    height: 22px;
    font-size: 0;
    margin-left: 10px;
    cursor: pointer;
}
.uploadInput label {
    border-bottom: 2px dotted rgba(35,31,32,0.5);
    padding-bottom: 2px;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
}
.uploadInput span {
    font-size: 14px;
    margin-left: 15px;
}
/*----------------------------------------------*
12.) Responsive Media Query CSS
/*----------------------------------------------*/
@media only screen and (max-width:1439px) {
	.container-fluid{padding-left:30px; padding-right:30px;}
}
@media only screen and (max-width:1365px) {
	.circle-background-animation::before{background-size:cover !important; height:500px; width:500px; }
}
@media only screen and (max-width:1279px) {
	.features-block.theme-three .inner-box{padding: 30px 10px;}
	.page-header.dc-six::before {left: -25%;}
}
@media only screen and (max-width:1199px) {
	.container-fluid{padding-left:15px; padding-right:15px;}
	.menu-primary > li.menu-item > a{padding: 0px 5px;}
	.container{max-width: 960px;}
	.right-col-full .col-lg-6:first-child, .right-col-full .col-md-6:first-child {padding-left:calc(((100vw - (100vw - 100%)) - 930px) / 2);}
	h1, .h1{font-size: 42px;}
	h2, .h2{font-size: 38px;}
	.single-post article h2, .h2 {font-size:32px;}
	.single-post article h3, .h3 {font-size:28px;}
	.single-post article h4, .h4 {font-size:24px;}
	.counter-wrapper{margin: 0px -15px;}
	.counter-block{padding: 0px 15px;}
	.counter-block .counter-number, .counter-block .counter-number-after{font-size: 42px;}
	.sidebar.right-side{padding-left:0px;}
	.sidebar.left-side{padding-right:0px;}
	.right-col-full .col-lg-6:first-child, .right-col-full .col-md-6:first-child{padding-left:15px;}
	.right-col-full .col-lg-6:last-child, .right-col-full .col-md-6:last-child{padding-right:15px;}
	.left-col-full .col-lg-6:last-child, .left-col-full .col-md-6:last-child{padding-right:15px;}
	.left-col-full .col-lg-6:first-child, .left-col-full .col-md-6:first-child{padding-left:15px;}
	.circle-background-animation::before{background-size:cover; height:450px; width:450px; }
	.features-block.theme-three .inner-box{padding: 30px 15px;}
	.dc-signin.theme-two .signin-wrapper{max-width: 850px;}
	.dc-signin.theme-two .intro-box{padding: 110px 30px 30px;}
	.dc-signin.theme-two .form-box{padding: 50px 30px;}
	.page-header.dc-seven::before{left:-55%;}
	.contact-box-wrapper .contact-details-box{flex:0 0 320px; max-width: 320px; padding: 50px 30px;}
	.contact-box-wrapper .form-box{padding: 50px 30px; flex: 0 0 calc(100% - 320px); max-width: calc(100% - 320px);}
}
@media only screen and (max-width:991px) {
	.i-text-center{text-align:center !important;}
	.i-hidden{display:none !important; }
	.search-option{display: none;}
	.container{max-width:720px;}
	.page-header.dc-one{padding: 150px 0px 100px;}
	.page-header.dc-one .image-wrapper{margin-top:30px;}
	.right-col-full .container, .left-col-full .container{max-width: 720px;}
	.call-to-action-btn{margin-top:30px;}
	.counter-wrapper{margin: 0px -15px 30px;}
	.screenshot-slider .slick-prev{left: 15px;}
	.screenshot-slider .slick-next{right: 15px;}
	.client-testimonial.theme-one{padding: 0px 0px; margin:0px auto 0;}
	.client-testimonial.theme-one .slick-slide{padding: 15px 30px 0px;}
	.client-testimonial.theme-one .slick-list {overflow: hidden !important;}
	.client-testimonial.theme-one .slick-prev, .client-testimonial.theme-one .slick-next{right: 30px;}
	.client-testimonial.theme-one .slick-prev {right: 80px;}
	.pricing-plans-one .price-card.popular{top:0;}
	.pricing-plans-one .price-card{margin: 30px auto 0; max-width: 420px;}
	.pricing-plans-two .price-card{margin:120px auto 0; max-width: 420px;}
	.icon-box.theme-one {margin: 30px auto 0; max-width: 420px;}
	.main-footer{padding:70px 0px;}
	.main-footer .widget{margin:30px 0px;}
	.footer-theme-one .main-footer{padding: 30px 0px 70px;}
	.footer-theme-one .footer-logo{top:-160px;}
	.footer-theme-one .footer-logo, .footer-theme-one .footer-logo::before{height:320px; width:320px;}
	.footer-theme-one{background-position:top left !important; }
	.footer-logo img{max-width:150px;}
	.icon-box.theme-two {margin: 30px auto 0; max-width: 420px;}
	.counter-block.with-icon .counter-icon{margin:0px auto 15px;}
	body.navigation-in {overflow: hidden !important;}
	body.navigation-in .page-wrapper {filter:blur(5px);}
	.menu-toggle-btn {display: block;}
	#navigation {background: #fff;position: fixed; right: 0; top: 0; -webkit-box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.1); height: 100vh; overflow: auto; z-index: 1; width: 100%; max-width: 360px;
    display: block; padding: 80px 0 30px; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; transform: translateX(120%);}
    #navigation.open {opacity: 1;visibility: visible; -webkit-transform: translateX(0); transform: translateX(0); z-index: 1;}
    #navigation .menu-primary {padding: 15px 0px 30px; width: 100%; margin: auto;}
    #navigation .navbar-right {padding: 0px 30px 30px;}
    #navigation .menu-primary li.menu-item {display: block; padding:0px 0px; border-bottom: 1px solid rgba(0,0,0,0.06);}
    #navigation .menu-primary li.menu-item:last-child {border-bottom:0px;}
    #navigation .menu-primary > li.menu-item:last-child{border-bottom: 1px solid rgba(0,0,0,0.06);}
    #navigation .menu-primary > li.menu-item > a{font-size:15px; display:block; padding:12px 30px; line-height:1.5; text-transform:uppercase; color: var(--heading-text-color);}
    .menu-primary > li.menu-item.menu-item-has-children > a::after{content:"";}
    .menu-primary .sub-menu > li.menu-item.menu-item-has-children > a::after {content:"";}
    .menu-primary li.menu-item .sub-menu{position:static; box-shadow:none;background:transparent; padding:0; transform: none;
	opacity: 1; visibility: visible; display: none; background: rgba(0,0,0,0.04); border-radius: 0; transition:none; -webkit-transition:none; }
	#navigation .menu-primary .child-link{display:block; position:absolute; right:10px;top:0px; height:46px; width:42px; text-align: center; line-height:46px; cursor:pointer; transition:all 500ms ease-in-out;  -webkit-transition:all 500ms ease-in-out; border-radius:50px; -webkit-border-radius:50px;  }
	.menu-primary > li.menu-item.mega-menu{position:relative; }
	.menu-primary .sub-menu > li.menu-item > a::before {display:none; }
	#navigation .menu-primary .sub-menu li.menu-item a{display:block; padding:12px 30px; line-height:1.5; color: var(--heading-text-color);}
	.menu-primary .sub-menu li.menu-item > .sub-menu{margin-left:0;}
	.menu-primary .sub-menu > li.menu-item.col-title > a{font-size: 18px; margin: 0;}
	.menu-primary .mega-menu-inner > li.menu-item > .sub-menu{display: block !important; background:transparent; }
	#navigation .menu-primary .child-link.active{transform:rotate(180deg); -webkit-transform:rotate(180deg); }
	.news-grid.column-2 .article-block{flex: 0 0 100%; max-width:100%;}
	.news-grid.column-3 .article-block{flex: 0 0 50%; max-width:50%;}
	.sidebar{margin-top:50px;}
	.vertical-tab .st-tab-btn{flex: 0 0 32%; width: 100%; max-width:32%;}
	.vertical-tab .st-tab-content{flex: 0 0 68%; width: 100%; max-width: 68%;}
	.companies-logo.grid-view{justify-content:center;}
	.companies-logo.grid-view .item{flex: 0 0 25%; max-width:25%;}
	.dc-signin.theme-two .signin-wrapper{max-width:750px;}
	.dc-signin.theme-two .intro-box{padding:120px 30px 30px; flex:0 0 55%; max-width:55%;}
	.dc-signin.theme-two .form-box{padding:30px 30px; flex:0 0 45%; max-width: 45%;}
	.page-header.dc-six::before {height: 100%; left: -16%; width: 120%; top: -36%;transform: rotate(-18deg);}
	.page-header.dc-six::after {background-size:100% auto; }
	.page-header.dc-seven::before{left:-70%;}
	.dc-features-group > .dc-features-item{border:0;}
	.dc-features-group > .dc-features-item:nth-child(n), .dc-features-group > .dc-features-item:nth-child(2n), .dc-features-group > .dc-features-item:nth-child(3n){border:0;}
	.features-block.theme-four .inner-box{display: table; padding: 40px 30px;}
	.features-block.theme-four .icon{margin: 0px auto 30px;}
	.features-block.theme-four .inner-box > *{width:100%; text-align:center;}
	.contact-box-wrapper .contact-details-box{flex:0 0 100%; max-width:100%; -webkit-border-radius:0px 0px 6px 6px;
	border-radius: 0px 0px 6px 6px;}
	.contact-box-wrapper .form-box{flex: 0 0 100%; max-width:100%;}
}
@media only screen and (max-width:767px) {
	.m-text-center{text-align:center !important;}
	.m-hidden{display:none !important; }
	.container{max-width:540px;}
	.right-col-full .container, .left-col-full .container{max-width:540px;}
	.screenshot-section{overflow:hidden; }
	.screenshot-slider .slick-prev{left:50%; top:100%; margin-top:40px; margin-left:-60px; }
	.screenshot-slider .slick-next{right:50%; top:100%; margin-top:40px;  margin-right:-60px; }
	h1, .h1{font-size:36px;}
	h2, .h2{font-size:32px;}
	h3, .h3{font-size:30px;}
	h4, .h4{font-size:26px;}
	.counter-wrapper{flex-wrap: wrap; margin: 0px -15px 0px;}
	.counter-block {padding: 0px 15px;flex: 0 0 50%; max-width: 50%; margin:15px 0px;}
	.counter-block::after{display:none;}
	.video-wrapper iframe{max-height:360px;}
	.dc-two.about-section .video-wrapper::before{height:300px; width: 300px;}
	.dc-two.about-section .video-player::before, .dc-two.about-section .video-player::after{height: 350px; width: 350px;}
	.dc-two.about-section .video-player::after {height: 400px; width: 400px;}
	.client-testimonial.theme-one .testimonial-text{padding: 30px 30px 30px 50px;}
	.footer-theme-one .footer-logo, .footer-theme-one .footer-logo::before{right:30px;}
	.pricing-plans-three .price-card{margin: 30px auto 0; max-width: 420px;}
	.counter-block.with-icon{width: 100%; max-width: initial;}
	.client-testimonial.theme-three{padding: 0px 0px 0px 0px; margin-left:0px; border: none;}
	.client-testimonial.theme-three .slick-dots{transform: none; margin:30px 0px 0px; position: static; padding:0 0 0 85px; text-align:left;}
	.client-testimonial.theme-three .slick-dots li{display: inline-block; margin: 0px 5px;}
	.dc-two.about-section .video-wrapper{padding:0px 0px 40px;}
	.video-wrapper iframe{width:100%; }
	.st-breadcrumb{font-size:15px; padding: 10px 15px;}
	.filters .select-filter {display:block;}
	.filters ul{text-align:left; border-bottom:0; padding: 30px 15px; background: var(--white-color); box-shadow: 0 0 10px 0px rgba(0,0,0,0.09); position: absolute; z-index: 1; display:none; left:15px; right:15px; width:auto;   }
	.filters ul li{display:block; border-bottom:1px solid var(--light-gray); padding:0; font-size:16px; }
	.filters li span{padding:15px 0px;}
	.filters li span::after{display:none; }
	.news-grid.column-3 .article-block{flex: 0 0 100%; max-width:100%;}
	.countdown-timer ul{margin: 0px -4px;}
	.countdown-timer ul li{padding: 0px 4px;}
	.countdown-timer ul li span{font-size:36px;}
	.vertical-tab .st-tab-btn{flex: 0 0 100%; width: 100%; max-width:100%; }
	.vertical-tab .st-tab-content{flex: 0 0 100%; width: 100%; max-width: 100%; padding:30px 0 0; border:0; }
	.select-filter{background: none; color: var(--heading-text-color); font-weight: 700; font-family: 'Source Sans Pro', sans-serif; font-size: 18px; padding: 15px;
	position: relative; border:1px solid var(--light-gray); cursor:pointer;}
	.st-tab-btn .nav-tabs .nav-item .nav-link::after {display:none;}
	.st-tab-btn .nav-tabs{border:none; box-shadow:0 0 15px 5px rgba(0,0,0,0.06); -webkit-box-shadow:0 0 15px 5px rgba(0,0,0,0.06); display:none; background:var(--white-color);}
	.st-tab-btn .nav-tabs .nav-item{margin:0; display:block; width: 100%;}
	.st-tab-btn{margin:0;}
	.select-filter:after{font-family: 'Line Awesome Free'; font-style: normal; font-size: 24px; content: "\f107"; color: var(--body-text-color); float: right; margin-top: -4px; font-weight: 900;}
	.vertical-tab .st-tab-btn .nav-tabs .nav-item .nav-link, .st-tab-btn .nav-tabs .nav-link{padding:15px; }
	.vertical-tab .st-tab-btn .nav-tabs{display:none;}
	.features-block.theme-three .inner-box {padding: 30px 15px;}
	.how-it-work-wrapper.three-step .item{flex: 0 0 100%; max-width: 100%; padding: 15px 0px 0px;}
	.page-header.dc-four{padding-bottom:120px;}
	.companies-logo.grid-view .item{flex: 0 0 33.3334%; max-width:33.3334%;}
	.dc-signin.theme-two .signin-wrapper{max-width:420px; flex-wrap: wrap;}
	.dc-signin.theme-two .intro-box{padding:50px 30px 80px; flex: 0 0 100%; max-width: 100%;}
	.dc-signin.theme-two .form-box{flex: 0 0 100%; max-width: 100%; padding:30px;}
	.dc-signin.theme-two .st-tab-btn{top: auto; right: 0; left: 0; bottom: 0;}
	.dc-signin.theme-two .st-tab-btn .nav-tabs{padding-top: 0;}
	.dc-signin.theme-two .st-tab-btn .nav-tabs {border: none;display: flex !important;padding-top: 0;flex-wrap: wrap;text-align: center; justify-content: center;background: none;box-shadow: none;}
    .dc-signin.theme-two .st-tab-btn .nav-tabs .nav-item{width:auto;}
    .dc-signin.theme-two .st-tab-btn .nav-tabs .nav-item .nav-link, .dc-signin.theme-two .st-tab-btn .nav-tabs .nav-link{border-radius: 6px 6px 0px 0px;}
    .dc-signin.theme-two .select-filter{display: none;}
    .page-header.dc-six::before{width: 140%; left: -30%;}
    .page-header.dc-seven::before{left:-70%; height:550px; width:550px;}
    .enquiry-form-box{padding:30px 0px 0px; }
    .enquiry-form-box .form-wrapper{width:100%; max-width:100%; }
    .client-testimonial.theme-four .slick-arrow{top: 100%; left:38%; transform:none;} 
    .client-testimonial.theme-four .slick-arrow.slick-next{right:38%; left:auto; }
}
@media only screen and (max-width:575px) {
	.features-section .inner-row .col-lg-6:nth-child(2), .features-section .inner-row .col-lg-6:nth-child(3){margin-top: 0;}
	.btn-wrapper .btn:first-child::after {display: table; clear: both;content: "";}
	.video-wrapper iframe{max-height:300px; width:100%;}
	.dc-two.about-section .video-wrapper::before{display:none; }
	.dc-two.about-section .video-player::before, .dc-two.about-section .video-player::after{display:none;}
	.client-testimonial.theme-one .slick-slide {padding:35px 15px 15px 15px;}
	.client-testimonial.theme-one .testimonial-text{padding: 50px 20px 30px 20px;}
	.client-testimonial.theme-one blockquote::before{top: -25px;left: 20px;}
	.client-testimonial.theme-one .testimonial-text::before, .client-testimonial.theme-one .testimonial-text::after{left:50px;}
	.btn-wrapper > .btn{display: table;}
	.text-center .btn-wrapper > .btn, .text-center.btn-wrapper > .btn{margin:0px auto;}
	.btn + .btn{margin-left:0; margin-top:15px; }
	.text-center .btn-wrapper > .btn + .btn, .text-center.btn-wrapper > .btn + .btn{margin:15px auto 0px;}
	.footer-theme-one {background-position: top left -90px !important;}
	.subscribe-form-wrapper .form-control{padding: 0px 15px 0px 15px; height: 48px; -webkit-border-radius: 6px; border-radius: 6px;}
	.subscribe-form-wrapper .btn{position:static; }
	.client-testimonial.theme-three .testimonial-text{padding:90px 0px 0px 0px; }
	.client-testimonial.theme-three .testimonial-text::before {top:0;}
	.client-testimonial.theme-three .slick-dots{padding:0;}
	.circle-background-animation{overflow:hidden;}
	.circle-background-animation::before{display:none;}
	.features-block.theme-three{max-width:320px; margin-left:auto; margin-right:auto; }
	.companies-logo.grid-view .item{flex: 0 0 50%; max-width: 50%;}
	.signin-wrapper{padding: 0px 15px 30px; max-width:100%; margin:30px 15px;}
	.dc-signin.theme-two .signin-wrapper{max-width:100%; margin:30px 15px; }
	.dc-signin.theme-two .intro-box{padding: 50px 15px 80px;}
	.dc-signin.theme-two .form-box{padding: 30px 15px;}
	.seo-rocket{display:none;}
	.features-block.theme-three h4 br{display:none;}
	.page-header.dc-seven::before{display:none;}
	.timeline .timeline-item:nth-child(2n) .timeline-wrapper{padding-top: 100px; padding-bottom: 0;}
	.timeline .timeline-item:nth-child(2n) .step-number {top: 30px; bottom: auto;}
	.timeline .timeline-item:nth-child(2n) .content{padding: 80px 0 0 0;}
	.client-testimonial.theme-four .slick-arrow{top: 100%; left:35%; transform:none;} 
    .client-testimonial.theme-four .slick-arrow.slick-next{right:35%; left:auto; }
    .client-testimonial.theme-four .client-info-wrapper::before {display:none;}
}
@media only screen and (max-width:419px) {
	.video-wrapper iframe{max-height:260px; width:100%;}
	.countdown-timer ul li{font-size: 11px;}
	.page-header.dc-four{padding-bottom:80px;}
	ul.two-col li{flex:0 0 100%; max-width:100%; width:100%;}
	ul.three-col li{flex:0 0 100%; max-width:100%; width:100%;}
	.social-media-links > h3{flex: 0 0 100%; margin:0 0 10px;}
	.social-media-links ul{flex: 0 0 100%;}
	.page-header.dc-six::before{width: 155%; left: -40%; height:110%; }
	.enquiry-form-box .form-wrapper{padding: 30px 15px;}
	.enquiry-form-box .form-wrapper .form-header h4{font-size: 24px;}
	.features-block.theme-five .inner-box{display: table;}
	.features-block.theme-five .icon{margin: 0px 0px 30px 0px; text-align: left; display: block;}
	.client-testimonial.theme-four .slick-arrow{top: 100%; left:34%; transform:none;} 
    .client-testimonial.theme-four .slick-arrow.slick-next{right:34%; left:auto; }
    .playstore-widget.stack-list ul{flex: 0 0 100%; padding: 0 0px 15px 0;}
    .playstore-widget.stack-list .qr-code-block{flex: 0 0 100%; width: 100%; text-align:center;}
}
.m_input {
    width: 100%;
}
.overlay {
	direction: ltr;
}

.section-padding-extra {
  padding-top: 120px; 
  padding-bottom: 120px !important; 
}