.Tutorial {}


/* The sidebar menu */
.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 240px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #111; /* Black */
  padding: 10px;
  z-index: 9999999999;

  /*overflow-x: hidden;  Disable horizontal scroll */
    display: flex;
    flex-direction: column;
}

/* The navigation menu links */
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Style page content */
.main {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}


.events {
	direction: rtl;

	li {
	  display: flex; 
	  color: #999;

	}
}

.events .active {
	color: #e4b037;
}
.events .success {
  color: #5aff07;
}

.events time { 
  position: relative;
  padding: 0 1.5em;  }

.events time::after { 
   content: "";
   position: absolute;
   z-index: 2;
   left: -13px;
   top: 0;
   transform: translateX(50%);
   border-radius: 50%;
   background: #fff;
   border: 1px #ccc solid;
   width: .8em;
   height: .8em;
}
.TutorialLeft {
  left: 0;
  right: auto;
}

.events span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
}

.events span::before {
   content: "";
   position: absolute;
   z-index: 1;
   right: 0;
   height: 100%;
   border-right: 1px #ccc solid;
}

.events strong {
   display: block;
   font-weight: bolder;
}
.events, 
.events *::before, 
.events *::after { box-sizing: border-box; font-family: arial; }


.popupOverlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 99999999;
    margin: 130px 10px;
    left: 0;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 600px;
    transition: all 1s ease-in-out;
}
.popup {
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}