/*
*   Template Name: FbZipper
*   Author Name: Mohamed OY.
*   Version: 1.0.0
*/
@import url(https://dl.dropboxusercontent.com/u/15746367/fonts/carmela/style.css);
@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);

.manage-group-container {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  background-color: #ffffff;
  font-family: "Alef Hebrew", sans-serif;

  #posts,
  #groups,
  .mh-75 {
    max-height: 75vh;
  }

  .cadre {
    padding-top: 100%;
    border-style: dashed !important;
  }
  .square {
    position: relative;
    padding-bottom: 100%;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .h-icon {
    position: relative !important;
    height: 15px !important;
    width: 15px !important;
  }

  .position-25 {
    position: absolute;
    top: 10px;
    left: -5px;
  }
  .position-75 {
    position: absolute;
    top: 10px;
    right: -5px;
  }

  .bg-dark-75 {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .bg-indigo {
    background-color: #585ef6;
  }
  .bg-blue {
    background-color: #4f80ea;
  }

  .close_btn {
    position: absolute;
    top: 0%;
    right: 0%;
    transform: translate(50%, -50%);
  }

  .m_input {
    background-color: rgba(252, 252, 252, 0);
    color: white;
    padding: 10px;
    border: none;
    border-left: 1px white solid;

    &::placeholder {
      color: rgb(197, 188, 188);
    }

    &:focus,
    &:active {
      border-left: solid 2px wheat;
      // background-color: rgba(211, 211, 182, 0.164);
      outline: none;
    }
  }

  .form-select {
    font-size: smaller;
  }

  #secondary_menu {
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);
    left: 0 !important;
  }
  // Custom Scroll bar

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6691e4;
    border-radius: 10px;
  }

  // Calendar table
  .date-control {
    font-size: x-small;
    font-weight: bolder;
    &::-webkit-calendar-picker-indicator {
      opacity: 0.5;
    }
  }

  .m_row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .m_col {
      position: relative;
    }

    .small {
      font-size: smaller;
    }
  }

  .m_s_row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .m_col-10 {
    }
    .m_col-70 {
      flex-basis: 75%;
      max-width: 75%;
    }
  }
}
.overlay {
    background: rgba(0,0,0,0.9);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}
.badge-blue {
    background: #326efd;
}

#main {
  display: flex;
  flex-wrap: wrap;

  #posts {
    width: 226px;
  }
  #calendar {
    flex: 1;
  }
  #groups {
    width: 226px;
  }
}

.calendar-column {
  width: 172px;
}
.react-datepicker-wrapper {
  input {
    max-width: 100%;
  }
}




.timeline-container {
  height: 200px;
  overflow: scroll;
}
.timeline {
    border-left: 3px solid #727cf5;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: rgba(114, 124, 245, 0.09);
    margin: 0 auto;
    letter-spacing: 0.2px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 50px;
    list-style: none;
    text-align: left;
    max-width: 40%;
    color: #000000;
}

@media (max-width: 767px) {
    .timeline {
        max-width: 98%;
        padding: 25px;
    }
}

.timeline h1 {
    font-weight: 300;
    font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
}

.timeline .event {
    border-bottom: 1px dashed #e8ebf1;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
}

@media (max-width: 767px) {
    .timeline .event {
        padding-top: 30px;
    }
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .event:before {
    left: -207px;
    content: attr(data-date);
    text-align: right;
    font-weight: 100;
    font-size: 0.9em;
    min-width: 120px;
}

@media (max-width: 767px) {
    .timeline .event:before {
        left: 0px;
        text-align: left;
    }
}

.timeline .event:after {
    -webkit-box-shadow: 0 0 0 3px #727cf5;
    box-shadow: 0 0 0 3px #727cf5;
    left: -55.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 5px;
}

@media (max-width: 767px) {
    .timeline .event:after {
        left: -31.8px;
    }
}

.rtl .timeline {
    border-left: 0;
    text-align: right;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
    left: 0;
    right: -170px;
}

.rtl .timeline .event::after {
    left: 0;
    right: -55.8px;
}



.select-option.active {
  border: 1px solid red;
}
.select-option {
  padding: 10px 40px;
  margin: 5px;
  border: 1px solid #ebe7e7;
  border-radius: 10%;
}
.icon {
  font-size: 60px;
}

.greyscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

#myForm{
  max-height: 80%;
  overflow: scroll;
}

.switch-toggle {
   float: left;
   background: #242729;
}
.switch-toggle input {
  position: absolute;
  opacity: 0;
}
.serial-key.row {
  background: #478cec;
}
.tutorial-title {
  text-align: center;
  color: #fff;
  font-size: 30px;
}
.switch-toggle input + label {
  padding: 7px;
  padding: 2px;
  float:left;
  color: #fff;
  cursor: pointer;
}
.switch-toggle input:checked + label {
  background: green;
}
.select-edit-post.selected .post {
    border: 5px solid red !important;
    display: none;
}
.permission-user{
  background: #9d7e7e;
  padding: 1px 2px;
  margin-right: 5px;
  border-radius: 11px;
  color: #000;
}
.permission-user .close{
  color: red;
  font-size: 12px;
}

.sq.circle {
    background: lightblue;
    border-radius: 50%;
    width: 70px;
    height: 70px;
}
.sq.triangle {
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-bottom: 70px solid lightblue;
}
.sq.sq-square {
    background: lightblue;
    width: 70px;
    height: 70px;
}
    .flexbox {
       display: flex;
       justify-content:  space-between;
       flex-wrap: wrap;
    }

.campaigns {
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  direction: ltr;
  flex-grow: 1;
}
.campaigns {
  overflow-x: auto;
}
.new-campaign {
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 227px;
  button {
        height: 64px;
  }
}

#info_campaign {
  white-space: nowrap;
  text-align: left;
}

.fl-l {
  float: left;
}
.fl-r {
  float: left;
}

.users-section {
  display: flex;
  height: 88px;
  padding: 0 33px 0 0;
}

.button-click {
  cursor: pointer;
}
.menu-item {
  cursor: pointer;
}

.users-section .users {
    overflow-x: auto;
    padding-top: 27px;
    padding-bottom: 10px;
    flex-grow: 1;
    white-space: nowrap;
    direction: ltr;
    text-align: left;
}

.users-section .add-new-user {
    float: left;
    clear: none;
    button {
      width: 227px !important;
      height: 82px;
    }
}
.campaign-section {
  display: flex;
  justify-content:flex-end;
  background: #fff;
}
.stats.show {
  direction: ltr;
}
.complete {
      border: 1px solid #28ff00 !important;
}
.complete.active {
      border: 1px solid red !important;
}
.custom-time {
    padding: 10px 40px;
    margin: 5px;
    border: 1px solid #ebe7e7;
    border-radius: 10%;
    text-align: center;
}
.custom-time-column {
  text-align: right;
  padding: 10px;
}
.custom-time-column .group{
  border-bottom: 1px solid #b6bac2f0;
}
.custom-groups .buttons {
  text-align: center;
}
.group-selection {
  direction: rtl;
  text-align: right;
}
.summary {
  color: lime;
  font-weight: bold;
}
.summary .title {
  font-weight: bold;
  color: #fff;
}

.blink-bg{
  animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground{
  0%    { background-color: #10c018;}
  25%   { background-color: #1056c0;}
  50%   { background-color: #ef0a1a;}
  75%   { background-color: #254878;}
  100%          { background-color: #04a1d5;}
}
.multiselect-container {
  direction: ltr;
}
.optionListContainer {
  background: #918181 !important;
}
.schedule-for-me > div {
  display: inline-block;
}
.adad {
  input {
    width: 100%;
    dir: rtl;
    text-align: right;
  }
}
.page-wrapper.home img {
  max-width: 100%;
}
.logo-container {
    padding: 25px 41px 25px;
}
.client-testimonial.theme-two .testimonial-text::after {
    z-index: 999;
}
.nav-link {
    padding: 7px 0px !important;
}
.child-menu{
  li {
    a {
      padding: 7px 10px !important;
      color: #000  !important;
      font-size: 15px !important;
      font-weight: normal !important;
    }

    a:hover{
      color: #323ec9 !important;
    }

    .nav-link.active {
      color: #323ec9 !important;
      font-weight: bold !important;
    }
  }
}
.pricing-plans-two .pricing-feature-list li {
    padding: 0px 30px 0px 0px;
    width: 222px;
}
.btn-secondary:hover, .btn-secondary:focus {
    background-color: #1460e7 !important;
    border-color: #005af9 !important;
    color: #fff !important;
}

#users {
  h2 {
    color: #000 !important;
  }
  color: #000;
}

.overlay .text-muted {
  color: #00f8ce !important;
}

.bd-highlight {
  position: relative;
}

.style-dark .sidepane {
  color: #000;
  small {
    color: #fff !important;
  }
  h4 {
    color: #fff;
  }
  .form-control {
    background: #fff;
    color: #000 ;
    height: 36px;
    &::placeholder {
      color: #000;
    }
  }
  .form-control.white-placeholder {
    &::placeholder {
      color: #fff;
    }
  }
}

.flex-fill {
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.flex-fixed {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.lhs {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 300px;
  max-height: 200px;
  border: 1px solid red;
  overflow: hidden;
}
.list-container {
    overflow-y: scroll;
}
.popup-position-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.popup-position-right {
  right: 240px;
}
.popup-position-v-bottom {
  bottom: 0px;
  margin-bottom: 0;
}
.copy-input {
    background: transparent !important;
    color: #fff !important;
    outline: 5px dotted red;
    font-size: 22px;
    text-align: center;
}

.pull-right{
  float:right;
}
.pull-left{
  float:left;
}
#fbcomment{
  background:#fff;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  color: #4b4f56;
  padding:50px;

  max-height: 500px;
  overflow-y: scroll;
}
.header_comment{
    font-size: 14px;
    overflow: hidden;
    border-bottom: 1px solid #e9ebee;
    line-height: 25px;
    margin-bottom: 24px;
    padding: 10px 0;
}
.sort_title{
  color: #4b4f56;
}
.sort_by{
  background-color: #f5f6f7;
  color: #4b4f56;
  line-height: 22px;
  cursor: pointer;
  vertical-align: top;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  padding: 4px;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #ccd0d5;
}
.count_comment{
  font-weight: 600;
}
.body_comment{
    padding: 0 8px;
    font-size: 14px;
    display: block;
    line-height: 25px;
    word-break: break-word;
}
.avatar_comment{
  display: block;
  padding-left: 12px;
}
.avatar_comment img{
  height: 48px;
  width: 48px;
}
.box_comment{
  display: block;
    position: relative;
    line-height: 1.358;
    word-break: break-word;
    border: 1px solid #d3d6db;
    word-wrap: break-word;
    background: #fff;
    box-sizing: border-box;
    cursor: text;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
  padding: 0;
}
.box_comment textarea{
  min-height: 40px;
  padding: 12px 8px;
  width: 100%;
  border: none;
  resize: none;
}
.box_comment textarea:focus{
  outline: none !important;
}
.box_comment .box_post{
  border-top: 1px solid #d3d6db;
    background: #f5f6f7;
    padding: 8px;
    display: block;
    overflow: hidden;
}
.box_comment label{
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  color: #90949c;
  line-height: 22px;
}
.box_comment button{
  margin-left:8px;
  background-color: #4267b2;
  border: 1px solid #4267b2;
  color: #fff;
  text-decoration: none;
  line-height: 22px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-align: center;
}
.box_comment button:hover{
  background-color: #29487d;
  border-color: #29487d;
}
.box_comment .cancel{
  margin-left:8px;
  background-color: #f5f6f7;
  color: #4b4f56;
  text-decoration: none;
  line-height: 22px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-align: center;
  border-color: #ccd0d5;
}
.box_comment .cancel:hover{
  background-color: #d0d0d0;
  border-color: #ccd0d5;
}
.box_comment img{
  height:16px;
  width:16px;
}
.box_result{
  position: relative;
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.box_reply {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
}
.box_result .result_comment {
  background: #d4cbcb;
  border-radius: 18px;
}
.box_result .result_comment textarea{
    width: 100%;
    background: transparent;
    outline: 0px;
    height: 85px;
    border: none;
    &::placeholder {
      color: #000;
    }
}
.box_result .result_comment h4{
  font-weight: 600;
  white-space: nowrap;
  color: #365899 !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.358;
  margin:0;
}
.box_result .result_comment{
  display:block;
  padding: 11px;

  flex: 1;
}
.child_replay{
  margin-top: 12px;
  list-style: none;
  padding: 0 60px 0 0;
  width: 100%;
  position: relative;
}
.like {
  position: relative;
  .like-box {
    position: absolute;
    top: 14px;
    right: 100%;
    z-index: 1;
    display: none;
  }
  &:hover {
    .like-box {
      display: block;
    }
  }
}
.avatar_comment {
  .comment-profile-container {
    .comment-gender {
      display: none;
    }
    &:hover {
      .comment-gender {
        display: block;
      }
    }
  }
}


.reply_comment{
  margin:12px 0;
}
.box_result .result_comment p{
  margin: 4px 0;
  text-align:justify;
}
.tools_comment {
  padding-right: 62px;
  width: 100%;
  position: relative;
}
.box_result .result_comment {
  font-size: 12px;
  line-height: 1.358;
}
.box_result .result_comment .tools_comment a{
  color: #4267b2;
  cursor: pointer;
  text-decoration: none;
}
.box_result .result_comment .tools_comment span{
  color: #90949c;
}
.body_comment .show_more{
  background: #3578e5;
  border: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-top: 24px;
  padding: 12px;
  text-shadow: none;
  width: 100%;
  font-weight:bold;
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
}

.avatar_comment:after {
    content: "";
    background: #7d7878;
    height: 100%;
    width: 2px;
    display: block;
    margin: auto;
}

.child_replay .avatar_comment:after {
    content: "";
    background: #7d7878;
    height: 100%;
    width: 2px;
    display: block;
    margin: auto;
    position: absolute;
    top: -40px;
    right: -37px;
}
.child_replay .avatar_comment:before {
    content: "";
    background: #7d7878;
    height: 2px;
    width: 37px;
    display: block;
    margin: auto;
    position: absolute;
    top: 45px;
    right: -35px;
}
.popup-position-left.parent-left {
    margin-left: 260px;
}

.btn-warning {
  color: #000 !important;
}


::-webkit-input-placeholder { 
  opacity: 0.5 !important;
}


.current-hour.current-day {
  border: 2px solid #478beb !important;
}
.current-hour.text-muted {
  color: red !important;
}









#users {
    background: white;
}

.current-hour {

}

.current-hour.current-day {

}

.posts {

}

.campaigns .active {

}

#posts {
  .selected {
    border: 3px dashed red !important;
  }
}




#calendar_head {
}

.hour.current-hour {
    background: #fff;
}

.manage-group-container {
  text-align: center;
  background: #478cec;
}

.filter-section-parant {
  background: #478cec;
}


.__react_component_tooltip {
  z-index: 9999999999 !important;
}







.steps-form {
  display: table;
  width: 100%;
  position: relative;

  .steps-row {
    display: table-row;

    &:before {
      top: 14px;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 100%;
      height: 1px;
      background-color: #ccc;
    }

    .steps-step {
      display: table-cell;
      text-align: center;
      position: relative;

      p {
        margin-top: 0.5rem;
      }

      button[disabled] {
        opacity: 1 !important;
        filter: alpha(opacity = 100) !important;
      }

      .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 15px;
        background: #7a7575;
        margin-top: 0;

        &.active {
          background: red;
          &.success {
            background: red;
          }
        }

        &.success {
          background: green;
        }
      }
    }
  }
}

.group-item-pick {
  border: 20px solid red;
  transition: all 0.5s;
  border: 2px solid black !important;
}

.selected-post-group {
  
}
.not-selected-post-group {
  opacity: 0.1 !important;
}
.rtl {
  direction: rtl;
  text-align: right;
}

.selected .multi-schedule-campaign {
  border: 1px solid red;
}
.multi-schedule-campaign {
  color: rgb(255, 255, 255);
  line-height: 41px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  height: 40px;
  border-radius: 100%;
  margin-top: 5px;
}

.edit-button {
  border-radius: 50rem!important;
  background-color: #17a2b8!important;
  cursor: pointer;

  position: absolute;
  bottom: -5px;
  left: 0;

  display: none !important;
}

.selected .edit-button {
  display: inline-block !important;
}

#list_comment .PrivateSwitchBase-root-1 {
  padding: 0 !important;
}

.automatic-approval-badge.automatic-approval-badge {
    top: 20px !important;
    transform: rotate(-45deg);
    background-color: #114f04 !important;
    width: 100%;
    left: -40px;
}

.by-selected-section {
      font-weight: bold;
      float: left;
}

.group-info-banner {
    background: #d9e8ed;
    padding: 5px 0px;
    line-height: 33px;
    padding-right: 10px;
    border-radius: 32px;
    .options {
      font-weight: normal;
    }
}
.groups {
  background: #d9e8ed;
}
.select-button {
  background: #28a745 !important;
  border-color: #28a745 !important;
}
.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.prfiles-label {
  color: #3445cd;
}
.profile-stats {
  min-width: 135px;

  .prfiles-label {
    color: #3445cd;
    font-weight: bold;
    &.value{
      color: #3445cd;
      font-weight: normal;
    }
  }
}

.form-check-input {
  &:checked {
    background-color: #fd0d8f !important;
    border-color: #0d6efd !important;
  }
}

.btn-primary-next {
    background-image: linear-gradient(to bottom, #25e00a 0%, #2c5c1c 100%);
    box-shadow: 0px 0px 30px 0px rgb(42 31 188 / 40%);
    background-size: auto 140%;
    border: 0px;
}


.calendar-column {
    background: #fafafa;
}








.carousel-root {
  width: 100% !important;
  margin: auto !important;
  margin-top: 3% !important;
  direction: ltr;
}

.carousel .slide {
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #fafafa ;
  margin-top: -6%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 550px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel.myCarousel h3 {
  color: #222 !important;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878 !important;
  font-size: 14px;
}

.myCarousel.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel.myCarousel p:before {
  content: "“";
  color: #aaa !important;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel.myCarousel p:after {
  content: "”";
  color: #aaa !important;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;

}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 100% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 369px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }

  .ss-wrapper .screenshot-slider {
    padding: 0 !important;
  }


  .logo-container {
    width: 100%;
    text-align: center;
  }

  .page-header.dc-two .heading-wrapper {
      margin-top: 0px !important;
  }

}

.contact-form input, .contact-form textarea {
    -webkit-transition: 0.5s;
    border: 1px solid black;
    opacity: 1;
    color: black;
}

.page-footer {
    position: fixed;
    width: 100%;
    height: 40px;
    background: red;
    top: calc(100% - 40px);
    color: #fff;
}

.form-group.dddd {
    color: black;
}

.merge-campaign {
      background: red !important;
}

.post-warning {
  color: #ff106bf5;
}
.inline-list-el {
  float: left;
  padding-right: 13px;
}
.user-block {
  overflow: hidden;
  display: block;
}
.links-area {
  height: 300px !important;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 6% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 590px !important;
  max-width: 90%;
}

.modal-content {
    
}


/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



/* CSS */
.bigger-package-button {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.bigger-package-button:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.bigger-package-button:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.bigger-package-button:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.bigger-package-button:focus {
  outline: 1px transparent;
}

.bigger-package-button:before {
  display: none;
}

.bigger-package-button:-webkit-details-marker {
  display: none;
}

.rti--container {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    color: black;
}
.form-control:disabled, .form-control[readonly] {
    background: #aaa6a6 !important;
}



.stepper {
  display: flex;
  flex-direction: row-reverse;
}

.step {
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 2px solid gray;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.checked {
  color: white;
}

.step:nth-child(1).checked {
  background-color: #007BFF; /* Blue */
}

.step:nth-child(2).checked {
  background-color: #28A745; /* Green */
}

.step:nth-child(3).checked {
  background-color: #DC3545; /* Red */
}

.step:nth-child(4).checked {
  background-color: #FFC107; /* Yellow */
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.badge-danger-waiting {
    color: #212529;
    background-color: #ffc107;
    animation: flash 1s infinite; /* 1 second duration, loops infinitely */
}

iframe{
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.relative {
  position: relative;
}