.popupOverlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    right: 0;
    top: 0;
    height: 100%;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    width: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    background: #fff;
    border-left: 1px solid #6e706f;
}
.header {
    border-bottom: 1px solid #6e706f;
}
.popup {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 100%;
  height: 100vh;
}



.dropdown {
    background: #cee6bf;
    border: 1px solid black;
    li {
        padding: 0px 10px;
        color: #000;
        border-bottom: 1px solid #c1acac;
    }
}

.tabs {
    background: #478cec;

    li{
        a {
            padding: 10px 10px !important;
            color: #fff !important;
        }
        border-left: 1px solid #5e5c5c;
    }
}

.activeTab.activeTab {
    color: #000 !important;
    background: #fff !important;
}


.tabs {
    li{
        a {
            padding: 10px 10px !important;
        }
    }
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

.body {
    background: #478cec;
    color: #fff;
    padding: 22px;
}
.footer {
    background: #478cec;
    padding: 10px 22px 10px 22px;
}
.title{
    background: #fff;
    text-align: center;
    h7 {
        font-size: 20px;
        color: #478cec !important;
        font-size: 27px;
        font-weight: bold;
    }
}

.SingleTaskPaneSpreadsheetHeader {
    border-bottom: 1px solid var(--color-border);
    flex-shrink: 0;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 56px;
    box-sizing: content-box;
    background: #478cec;
}
.ThemeableIconButton {
    margin: 0 6px;
    padding: 0 3px;
}

.SingleTaskPaneSpreadsheetHeader .SingleTaskPaneToolbarAnimation {
    flex-grow: 1;
    min-width: 1px;
    height: 56px;
    margin-right: 8px;
    overflow-y: hidden;
}
